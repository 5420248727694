import React, { useContext } from 'react';
import { Dialog, DialogActions, Box, DialogContent } from '@material-ui/core';
import { styled, Theme } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import { Logout } from 'mdi-material-ui';
import { PopoverContext } from 'share/context';
import { TypoThreeDot } from 'share/component_css';
import { ButtonCopyLink, ButtonLoading } from 'components';
import { FlowFragment } from 'types.d';
import { useToogleDialog } from 'hooks';
import DialogNavigatorFlow from './NavigatorFlow/DialogNavigatorFlow';

const ViewQrCodeStyled = styled('div')(
  ({ src, theme }: { src: string; theme: Theme }) => ({
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.common.black,
  }),
);

const DialogStyled = styled(Dialog)({
  '& .MuiPaper-root': {
    maxWidth: '500px',
  },
});

const DialogContentStyled = styled(DialogContent)({
  padding: '10px 100px',
});

const DialogActionStyled = styled(DialogActions)({
  padding: '24px',
  gap: '8px',
});

const WrapImageQrCode = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
});

type Props = {
  open: string;
  setOpenModal: React.Dispatch<React.SetStateAction<string>>;
  close?: () => void;
  flow: FlowFragment;
};

export const ViewQrCode: React.FC<Props> = ({
  open,
  setOpenModal,
  close,
  flow,
}) => {
  const [flowView, setFlowView] = useToogleDialog();

  const popoverContext = useContext(PopoverContext);
  const handleClose = () => {
    setOpenModal('');
    if (close) {
      close();
    }
  };

  const handleOpen = () => {
    setFlowView(true);
  };

  return (
    <>
      {flowView && (
        <DialogNavigatorFlow
          open={flowView}
          toogleDialog={setFlowView}
          flow={flow}
        />
      )}
      <DialogStyled
        open={open === 'qrCode'}
        onClose={handleClose}
        fullWidth={true}
        onExited={() => {
          popoverContext?.closePopover();
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gridGap={8}
          p="16px 16px 0px 16px"
        >
          <TypoThreeDot align="center" variant="h6">
            {flow?.name}
          </TypoThreeDot>
          <div id="append-view-qrcode">
            <ButtonCopyLink
              hasElemAppend="append-view-qrcode"
              link={
                `${process.env.REACT_APP_DOMAIN}${flow?.urlSharing}` as string
              }
            />
          </div>
        </Box>
        <DialogContentStyled style={{ padding: '10px 100px' }}>
          <WrapImageQrCode>
            <ViewQrCodeStyled
              src={`${process.env.REACT_APP_LINK_S3}${process.env.REACT_APP_MEDIA_BUCKET}/${flow?.qrCode}`}
            />
          </WrapImageQrCode>
        </DialogContentStyled>
        <DialogActionStyled>
          <ButtonLoading
            fullWidth
            Icon={<Logout />}
            text="Open"
            variant="contained"
            callbackClick={handleOpen}
          />
          <a
            href={`${process.env.REACT_APP_LINK_S3}${process.env.REACT_APP_MEDIA_BUCKET}/${flow?.qrCode}`}
            style={{ width: '100%' }}
            aria-label="print"
          >
            <ButtonLoading
              fullWidth
              Icon={<PrintIcon />}
              text="Print"
              variant="contained"
            />
          </a>
        </DialogActionStyled>
      </DialogStyled>
    </>
  );
};
export default ViewQrCode;
