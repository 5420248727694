import React, { useMemo, useEffect, useContext, useRef, useState } from 'react';
import { GET_DATA_DETAIL_TREATMENT, GET_PATH_TO_TREATMENT } from 'gql';
import {
  GetDataDetailTreatmentQuery,
  GetDataDetailTreatmentQueryVariables,
  GetPathToTreatmentQuery,
  GetPathToTreatmentQueryVariables,
} from 'types.d';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  Divider,
  CardMedia,
  Card,
  CardContent,
  Hidden,
} from '@material-ui/core';
import { useTheme, styled } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import {
  MediaStyled,
  GridSurveyStyled,
  TypographyBold,
  LinkRouterStyled,
} from 'share/component_css';
import {
  getLocalAssignTreatment,
  sortTreatment,
  getError,
  getSessionStorage,
  setDataSessionStorage,
  getLocalStorage,
  removeSessionStorage,
  removeLocalStorage,
  setDataLocalStorage,
  renderMedia,
  sortSubTreatment,
} from 'share/utils';
import { GridMarkDownStyled } from '../styles';
import {
  MediaTreatment,
  BreadCrumbComponent,
  ErrorPage,
  LoadingTreatment,
  SurveyDisplay,
  ScrollToTop,
} from 'components';
import { useQueryCustom, useCheckLogin, useCustomPermission } from 'hooks';
import {
  CheckHightLightContext,
  TreatmentDetailContext,
  LayoutContext,
} from 'share/context';
import { useLazyQuery } from '@apollo/react-hooks';
import PopoverComponent from './PopoverComponent';
import ChatResults from 'modules/chatbot/components/ChatResults';
import GridSubTreatment from './GridSubTreatment';
import { GET_HOMEPAGE } from 'gql/server/homepage';
import {
  KEY_LOCAL_ASSIGNED_SURVEYS,
  KEY_LOCAL_NO_LOG_KEY,
  KEY_SESSION_ORGANIZATION_ID,
} from 'CONST';
import { useUpdateHomepageClient } from 'hooks/useUpdateHomepage';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import {
  CarouselArrowLeft,
  CarouselArrowRight,
} from 'modules/landing_page/styles';
import { organizationEvent } from 'modules/google_tag_manager';
import SocialShare from './SocialShare';
import Speak from './Speak';
import GridRelatedArticles from './GridRelatedArticles';
import ReactDOMServer from 'react-dom/server';

export const GridContainerStyled = styled(Grid)(
  ({ chatbotDrawer }: { chatbotDrawer: boolean | undefined }) => ({
    display: 'grid',
    gridTemplateColumns: chatbotDrawer
      ? 'minmax(0, 900px)'
      : 'minmax(0, 400px) minmax(0, 900px)',
    justifyContent: 'center',
    gap: '20px',
    padding: '0 10px',
    '@media (max-width: 950px)': {
      gridTemplateColumns: '1fr',
    },
  }),
);

export const TreatmentHeadingStyled = styled(Typography)({
  fontSize: '42px',
  fontWeight: 'bold',
});

const ChatGridStyled = styled(Grid)(
  ({ displayChatbot }: { displayChatbot: boolean }) => ({
    top: '10%',
    position: displayChatbot ? 'sticky' : 'sticky',
    maxHeight: displayChatbot ? '84vh' : '84vh',
  }),
);

const CardStyled = styled(Card)(({ theme }) => ({
  display: 'flex',
  border: 'none',
  margin: '10px 0',
  overflow: 'hidden',
  height: '96px',
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 8px',
}));

const TreatmentTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 500,
}));

const ShortDescriptionStyled = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
}));

const cardMediaStyling = {
  width: '168px',
  height: '94px',
  borderRadius: '10px',
  border: '1px solid lightgray',
};

type Props = {};

export const Treatment: React.FC<Props> = () => {
  let { id } = useParams();

  const history = useHistory();

  const location = useLocation();

  const queryParams: any = new URLSearchParams(location.search);

  const search = queryParams.get('chatbot');

  const [displayChatbot, setDisplayChatBot] = useState(false);

  const theme = useTheme();

  const isOpenedLogin = useRef(false);

  const { isAdmin, isNavigator } = useCustomPermission();

  const isLogin = useCheckLogin();

  const layoutContext = useContext(LayoutContext);

  const isMatchSlug = !!useRouteMatch('/treatment/:id/');

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const { handleUpdateHomepageClient } = useUpdateHomepageClient();

  const [highlightedIndex, setHighlightedIndex] = useState<any>(undefined);

  const [textWords, setTextWords] = useState<string>('');

  const paramsGettingDetail = useMemo(() => {
    return isMatchSlug ? { slug: id } : { _id: id };
  }, [id, isMatchSlug]);

  const { loading, data, error, refetch } = useQueryCustom<
    GetDataDetailTreatmentQuery,
    GetDataDetailTreatmentQueryVariables
  >({
    api: GET_DATA_DETAIL_TREATMENT,
    variables: {
      paramsDetail:
        getLocalAssignTreatment().length === 0
          ? paramsGettingDetail
          : {
              ...paramsGettingDetail,
              treatmentIds: getLocalAssignTreatment().map(item => {
                return {
                  _id: item._id,
                  token: item.token,
                };
              }),
            },
    },
    callbackSuccess: data => {
      if (
        data.getTreatment?.organization._id !==
        getSessionStorage(KEY_SESSION_ORGANIZATION_ID)
      ) {
        getHomepage({
          variables: {
            params: {
              organization: data.getTreatment?.organization._id,
            },
          },
        });
      }

      const { _id, slug, content } = data!.getTreatment!;

      const { start, end } = Object.fromEntries(queryParams.entries());

      if (layoutContext && content && slug) {
        layoutContext.handleOpenedTreatment(_id, slug, content);

        if (start && end) {
          const filteredContent = (
            <ReactMarkdown
              source={content.slice(parseInt(start), parseInt(end))}
            />
          );
          const innerHtml = ReactDOMServer.renderToString(filteredContent);

          layoutContext?.scrollToElement(innerHtml);
        }
      }

      loadPathTreatment({
        variables: {
          params: {
            _id: data.getTreatment?._id,
          },
        },
      });
    },
  });

  const errorCode = useMemo(() => {
    return getError(error, true);
  }, [error]);

  const [
    loadPathTreatment,
    { data: dataPath, error: errorPath, loading: loadingPath },
  ] = useLazyQuery<GetPathToTreatmentQuery, GetPathToTreatmentQueryVariables>(
    GET_PATH_TO_TREATMENT,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    //open login popup when user haven't logged into system
    if (errorCode === 'IsCustomUnAuthenticated' && !isOpenedLogin.current) {
      layoutContext?.toogleDialogLogin(true);
      layoutContext?.setIsRedirectingToHome();
      isOpenedLogin.current = true;
    }
  }, [errorCode, layoutContext]);

  useEffect(() => {
    if (isOpenedLogin.current && isLogin) {
      refetch();
    }
  }, [isLogin, refetch]);

  useEffect(() => {
    if (search) {
      setDisplayChatBot(search);
      if (queryParams.has('chatbot')) {
        queryParams.delete('chatbot');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
  }, [history, queryParams, search]);

  const [getHomepage] = useLazyQuery(GET_HOMEPAGE, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      handleUpdateHomepageClient(data);
      if (data && data?.getHomePageConfig?.organization.name) {
        organizationEvent({
          id: data?.getHomePageConfig?.organization._id,
          name: data?.getHomePageConfig?.organization.name,
        });
      }
      if (!isLogin && data && data?.getHomePageConfig?.organization._id) {
        setDataSessionStorage(
          KEY_SESSION_ORGANIZATION_ID,
          data?.getHomePageConfig?.organization._id,
        );
      } else {
        var orgId = getSessionStorage(KEY_SESSION_ORGANIZATION_ID)!;
        var noLogKey = getLocalStorage(KEY_LOCAL_NO_LOG_KEY)!;
        if (orgId) {
          removeSessionStorage(KEY_SESSION_ORGANIZATION_ID);
        }
        if (noLogKey) {
          removeLocalStorage(KEY_LOCAL_NO_LOG_KEY);
        }
      }
      assignedSurveys();
    },
  });

  function assignedSurveys() {
    if (isLogin) {
      var assignedSurveys = JSON.parse(
        getLocalStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
      removeSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS);
    } else {
      var assignedSurveys = JSON.parse(
        getSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
    }
    if (assignedSurveys != null && assignedSurveys.length > 0) {
      assignedSurveys.filter((survey: any) => {
        if (new Date() < new Date(survey.expiredDate)) return survey;
      });
      if (isLogin) {
        setDataLocalStorage(
          KEY_LOCAL_ASSIGNED_SURVEYS,
          JSON.stringify(assignedSurveys),
        );
      } else {
        setDataSessionStorage(
          KEY_LOCAL_ASSIGNED_SURVEYS,
          JSON.stringify(assignedSurveys),
        );
      }
    }
  }

  const getTreatments = () => {
    if (!data) return [];

    if (
      data.getTreatment?.subSections &&
      data!.getTreatment!.subSections!.length > 0
    ) {
      return sortSubTreatment(data?.getTreatment?.subSections);
    } else {
      return (
        dataPath?.getPathToTreatment[
          dataPath?.getPathToTreatment.length === 1 ? 0 : 1
        ].subSections.filter(item => item.subId !== data?.getTreatment?._id) ||
        []
      );
    }
  };

  function styleTables() {
    var tables = document.querySelectorAll('table');

    if (window.innerWidth < 768) {
      // Check if the device is a mobile device
      tables.forEach(function(table) {
        // table.style.width = '100%';
        table.style.borderCollapse = 'collapse';
        table.style.display = 'block';
        table.style.overflowX = 'auto';
        table.style.maxWidth = window.innerWidth - 75 + 'px';
      });
    }
  }
  useEffect(() => {
    if (data && isMobile) {
      styleTables();
    }
  }, [data]);

  return (
    <>
      <TreatmentDetailContext.Provider
        value={{
          treatmentId: data?.getTreatment?._id,
        }}
      >
        <CheckHightLightContext.Provider
          value={{
            stageHightLight:
              data?.checkHightLight?.map(item => item.linkTo?._id) || [],
          }}
        >
          {(loading || loadingPath) && <LoadingTreatment />}
          {(['FORBIDDEN', 'IsCustomExists'].includes(errorCode) ||
            errorPath) && (
            <ErrorPage
              error={
                errorCode === 'FORBIDDEN'
                  ? 'notAccessTreatment'
                  : 'notFoundTreatment'
              }
            />
          )}

          {errorCode === 'IsCustomUnAuthenticated' && (
            <>
              <BreadCrumbComponent custom={[]} />
              <TypographyBold className="mt-16" align="center" variant="h5">
                This treatment is private. You need to login into TreatmentGPS
                to see detail !
              </TypographyBold>
            </>
          )}
          {!errorPath && !error && data && !loading && (
            <>
              {/* <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box maxWidth={'1180px'} margin={'0 auto'}>
                    {dataPath?.getPathToTreatment && (
                      <BreadCrumbComponent
                        custom={[...dataPath.getPathToTreatment].reverse()}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid> */}
              <GridContainerStyled
                container
                spacing={3}
                chatbotDrawer={isTablet && layoutContext?.chatbotDrawer}
              >
                <Box
                  style={{
                    display: isMobile
                      ? 'block'
                      : isTablet && layoutContext?.chatbotDrawer
                      ? 'none'
                      : 'block',
                  }}
                >
                  {!isMobile ? (
                    <ChatGridStyled item displayChatbot={displayChatbot}>
                      <ChatResults
                        isChatbot={displayChatbot}
                        handleChatbotDisplay={setDisplayChatBot}
                        fromAnotherComp={true}
                        relatedArticles={getTreatments()}
                        subSectionIds={
                          dataPath?.getPathToTreatment
                            ? dataPath?.getPathToTreatment.length > 1
                              ? dataPath?.getPathToTreatment[
                                  dataPath?.getPathToTreatment.length - 1
                                ].subSections.map(item => item.subId)
                              : []
                            : []
                        }
                      />
                    </ChatGridStyled>
                  ) : (
                    <ChatResults fromAnotherComp={true} />
                  )}
                </Box>
                <Grid item>
                  <Hidden lgUp>
                    {/* {!isMobile && layoutContext?.chatbotDrawer && ( */}
                    <Box
                      padding="20px 0px"
                      maxWidth={window.innerWidth - 50 + 'px'}
                      margin={'0 auto'}
                    >
                      <Carousel
                        // autoPlay
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        showArrows={true}
                        centerMode
                        centerSlidePercentage={100 / 1}
                        renderArrowPrev={(onClickHandler, hasPrev) =>
                          hasPrev && (
                            <CarouselArrowLeft
                              onClick={onClickHandler}
                              style={{
                                border: '2px solid #1976D2',
                                color: '#1976D2',
                                left: '0px',
                                top: 'calc(50% - 20px)',
                                height: '40px',
                                width: '40px',
                              }}
                            >
                              <ChevronLeft
                                color="inherit"
                                style={{
                                  fontSize: '3rem',
                                }}
                              />
                            </CarouselArrowLeft>
                          )
                        }
                        renderArrowNext={(onClickHandler, hasNext, _label) =>
                          hasNext && (
                            <CarouselArrowRight
                              onClick={onClickHandler}
                              style={{
                                border: '2px solid #1976D2',
                                color: '#1976D2',
                                right: '0px',
                                top: 'calc(50% - 20px)',
                                height: '40px',
                                width: '40px',
                              }}
                            >
                              <ChevronRight
                                color="inherit"
                                style={{
                                  fontSize: '3rem',
                                }}
                              />
                            </CarouselArrowRight>
                          )
                        }
                      >
                        {getTreatments()!.map((subtreatment, index) => (
                          <Box key={index} padding={'0px 50px'}>
                            <LinkRouterStyled
                              to={`/treatment/${subtreatment?.slug}`}
                            >
                              <CardStyled
                                style={{
                                  textAlign: 'left',
                                }}
                              >
                                <Box
                                  position={'relative'}
                                  top={0}
                                  right={0}
                                  left={0}
                                  bottom={0}
                                >
                                  <CardMedia
                                    component="img"
                                    image={renderMedia(subtreatment.mainImage)}
                                    alt="Thumbnail"
                                    style={cardMediaStyling}
                                  />
                                </Box>
                                <CardContentStyled>
                                  <TreatmentTitleStyled variant="body2">
                                    {subtreatment.name}
                                  </TreatmentTitleStyled>
                                  <ShortDescriptionStyled
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {subtreatment?.shortDescription}
                                  </ShortDescriptionStyled>
                                </CardContentStyled>
                              </CardStyled>
                            </LinkRouterStyled>
                          </Box>
                        ))}
                      </Carousel>
                    </Box>
                    {/* // )} */}
                  </Hidden>
                  {dataPath?.getPathToTreatment && (
                    <BreadCrumbComponent
                      custom={[...dataPath.getPathToTreatment].reverse()}
                    />
                  )}
                  <TreatmentHeadingStyled
                    variantMapping={{
                      h5: 'h2',
                    }}
                    variant="h5"
                  >
                    {data!.getTreatment!.name}
                    {(isAdmin || isNavigator) && (
                      <PopoverComponent
                        treatmentSelected={data?.getTreatment}
                        qrCode={data?.getTreatment?.qrCode as string}
                        urlSharing={data?.getTreatment?.urlSharing as string}
                      />
                    )}
                  </TreatmentHeadingStyled>
                  <Box my={1}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      // style={{
                      //   backgroundColor:
                      //     highlightedIndex && data!.getTreatment!.description
                      //       ? 'rgba(0, 0, 0, 0.20)'
                      //       : 'transparent',
                      // }}
                    >
                      {data!.getTreatment!.description}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    mb={2}
                  >
                    <SocialShare data={data!.getTreatment!} />
                    <Speak
                      data={data!.getTreatment!}
                      setHighlightedIndex={setHighlightedIndex}
                      textWords={textWords}
                      setTextWords={setTextWords}
                    />
                  </Box>
                  {data?.getTreatment?.hideMainMedia !== true && (
                    <MediaStyled>
                      <MediaTreatment
                        mainVideo={data!.getTreatment!.mainVideo!}
                        mainImage={data!.getTreatment!.mainImage!}
                      />
                    </MediaStyled>
                  )}
                  <GridMarkDownStyled
                    xs={12}
                    item
                    style={{
                      margin: '0',
                      padding: '0',
                    }}
                  >
                    <div ref={layoutContext?.targetRef}>
                      <ReactMarkdown
                        linkTarget="_blank"
                        source={data!.getTreatment!.content!}
                      />
                    </div>
                  </GridMarkDownStyled>
                  {data?.getTreatment?.survey?.length && (
                    <GridSurveyStyled xs={12} item>
                      {sortTreatment(data?.getTreatment?.survey).map(
                        item =>
                          !item.isPrivate && (
                            <SurveyDisplay
                              key={item.idSurvey}
                              idSurvey={item.idSurvey}
                              surveyData={item.surveyData as string}
                            />
                          ),
                      )}
                    </GridSurveyStyled>
                  )}

                  {/* {data?.getTreatment?.subSections && (
                    <>
                      {data?.getTreatment?.subSections.length > 0 && (
                        <Box mb={2}>
                          <Typography variant="h6">Further Reading</Typography>
                        </Box>
                      )}
                      <GridSubTreatment
                        subTreatments={data?.getTreatment?.subSections}
                      />
                    </>
                  )} */}

                  {data?.getTreatment?.relatedArticles && (
                    <>
                      {data?.getTreatment?.relatedArticles.length > 0 && (
                        <Box my={2}>
                          <Typography variant="h6">Recommendations</Typography>
                        </Box>
                      )}
                      <GridRelatedArticles
                        subTreatments={data?.getTreatment?.relatedArticles}
                      />
                    </>
                  )}

                  <Box mb={4}>
                    <Divider />
                  </Box>

                  {data.getTreatment?.subSections &&
                    data.getTreatment?.subSections?.length > 0 &&
                    dataPath?.getPathToTreatment &&
                    dataPath?.getPathToTreatment?.length > 1 && (
                      <>
                        {!data?.getTreatment?.relatedArticles && (
                          <Box mb={2}>
                            <Typography variant="h6">
                              Recommendations
                            </Typography>
                          </Box>
                        )}
                        <GridSubTreatment
                          subTreatments={dataPath?.getPathToTreatment[
                            dataPath?.getPathToTreatment.length - 1
                          ].subSections.filter(
                            item => item.subId !== data?.getTreatment?._id,
                          )}
                        />
                      </>
                    )}
                </Grid>
              </GridContainerStyled>
            </>
          )}
          <ScrollToTop />
        </CheckHightLightContext.Provider>
      </TreatmentDetailContext.Provider>
    </>
  );
};
export default Treatment;
