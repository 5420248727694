import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Box,
  Fab,
  useMediaQuery,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Tooltip,
} from '@material-ui/core';
import { useTheme, styled } from '@material-ui/core/styles';
import { Chatbot } from './Chatbot';
import {
  ChatBotHistoryKeyQuery,
  ChatBotHistoryQuery,
  ChatBotQuery,
  QueryChatBotArgs,
  QueryChatBotHistoryArgs,
} from 'types.d';
import {
  ChatBot_History,
  ChatBot_LogKey,
  ChatBot_Query,
} from 'gql/server/chatbot';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  getLocalStorage,
  getSessionStorage,
  handleScrollClick,
  processContexts,
  renderMedia,
  setDataLocalStorage,
  setDataSessionStorage,
} from 'share/utils';
import { useLocation, useHistory } from 'react-router-dom';
import MobileChatBot from './MobileChatbot';
import TreatmentCard from 'modules/home/components/TreatmentCard';
import {
  CONTEXT_TREATMENTS,
  DEFAULT_VOICE_SETTINGS,
  KEY_LOCAL_ASSIGNED_SURVEYS,
  KEY_LOCAL_ASSIGN_TREATMENT,
  KEY_LOCAL_NO_LOG_KEY,
  KEY_SESSION_ORGANIZATION_ID,
  SHOW_FORMS,
} from 'CONST';
import { Chat } from '@material-ui/icons';
import { LoadingTreatment, NoDataPage } from 'components';
import { useCheckLogin, useCustomPermission, useEffectOnlyOnce } from 'hooks';
import { LinkRouterStyled } from 'share/component_css';
import { ChatbotRan } from 'modules/google_tag_manager';

type Props = {
  fromAnotherComp?: boolean;
  handleChatbotDisplay?: any;
  isChatbot?: boolean;
  relatedArticles?: any;
  subSectionIds?: any;
};

const FloatingChatStyled = styled(Fab)(({ theme }) => ({
  position: 'fixed', // Stick to the bottom
  bottom: theme.spacing(8), // Margin from the bottom
  right: theme.spacing(2), // Margin from the right
  zIndex: 11112,
}));

const ChatGridStyled = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '100%',
}));

const WebChatbotGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '82vh',
  borderWidth: '1px',
  borderStyle: 'solid',
  marginTop: '6px',
  borderColor: 'rgb(238, 238, 238)',
  borderRadius: '10px',
}));

export const ChatbotHeadingStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1rem',
}));

const CardStyled = styled(Card)(({ theme }) => ({
  display: 'flex',
  border: 'none',
  margin: '10px 0',
  overflow: 'hidden',
  height: '96px',
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 8px',
}));

const TreatmentTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 500,
}));

const ShortDescriptionStyled = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
}));

const cardMediaStyling = {
  width: '168px',
  height: '94px',
  borderRadius: '10px',
  border: '1px solid lightgray',
};

export const ChatResults: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams: any = new URLSearchParams(location.search);
  const message = queryParams.get('search');
  const isLogin = useCheckLogin();
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const { isPatient, isUser } = useCustomPermission();
  const treatmentAssigneeEmail =
    JSON.parse(getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)!) != null &&
    JSON.parse(getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)!).length > 0
      ? JSON.parse(getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)!).find(
          (treatment: { email: any }) => treatment.email,
        )?.email
      : null;
  const [loading, setLoading] = useState(false);
  const [chatBot, setChatBot] = useState(true);
  const [messages, setMessages] = useState<any[]>([]);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [assignedSurvey, setAssignedSurvey] = useState<any>(false);
  const [surveyInitiated, setSurveyInitiated] = useState(false);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [inputMessage, setInputMessage] = useState('');
  const [displayChatbot, setDisplayChatBot] = useState(false);
  const [logKey, setLogKey] = useState(
    treatmentAssigneeEmail != null && treatmentAssigneeEmail != 'null'
      ? treatmentAssigneeEmail
      : getLocalStorage(KEY_LOCAL_NO_LOG_KEY)!,
  );
  const [historyKey, setHistoryKey] = useState<any>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [responseAdded, setResponseAdded] = useState(false);

  const [
    treatmentContextFilteredIds,
    setTreatmentContextFilteredIds,
  ] = useState<string[]>(() => {
    const storedValue = getLocalStorage(CONTEXT_TREATMENTS);
    return storedValue ? storedValue.split(',') : [];
  });

  let params: QueryChatBotHistoryArgs['params'] = {
    nologkey: logKey,
  };
  var voice_settings = JSON.parse(getLocalStorage(DEFAULT_VOICE_SETTINGS)!);

  const [
    getSearchResults,
    { data: dataSearchResults, loading: laodingSearchResults },
  ] = useLazyQuery<ChatBotQuery, QueryChatBotArgs>(ChatBot_Query);

  useEffect(() => {
    getSearchResults({
      variables: { params: { query: message, isSearch: true } },
    });
  }, []);

  const [chatbot] = useLazyQuery<ChatBotQuery, QueryChatBotArgs>(
    ChatBot_Query,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted(data) {
        setInputMessage('');
        const results = data.chatbot?.answer;
        var temporarySurveys: any[] = [];

        if (data.chatbot?.relatedtreatments.length > 0) {
          setSearchResults(data.chatbot?.relatedtreatments);
          data.chatbot.relatedtreatments.forEach(rt => {
            if (
              (!isLogin && rt?.survey != null && rt?.survey.length > 0) ||
              (rt?.survey != null &&
                rt?.survey.length > 0 &&
                isLogin &&
                (isPatient || isUser))
            ) {
              rt.survey.forEach(survey => {
                temporarySurveys.push({
                  surveyId: survey._id,
                  name: survey.name,
                  slug: survey.slug,
                  survey: survey.surveyData,
                });
              });
            }
          });
        }

        temporarySurveys = getAssignedSurveys(temporarySurveys);

        if (results) {
          if (messages.length == 0) {
            messages.push({ text: inputMessage, type: 'user' });
          }
          if (temporarySurveys.length > 0) {
            setMessages([
              ...messages,
              {
                text: results,
                type: 'bot',
                responseId: data.chatbot?.responseId,
                feedback: false,
                settings: false,
                pitch: voice_settings.pitch,
                rate: voice_settings.rate,
                //vol: 0.8,
                voice: voice_settings.voice,
                isMarkdown: true,
                played: undefined,
                relatedArticles: data.chatbot?.relatedtreatments,
                contexts: data.chatbot.contexts
                  ? processContexts(data.chatbot.contexts)
                  : [],
                isForm: data.chatbot?.isForm,
                formName: data.chatbot.formName,
                logKey,
                historyKey,
              },
              { text: '', type: 'bot', surveys: temporarySurveys },
            ]);
          } else {
            setMessages([
              ...messages,
              {
                text: results,
                type: 'bot',
                responseId: data.chatbot?.responseId,
                feedback: false,
                settings: false,
                pitch: voice_settings.pitch,
                rate: voice_settings.rate,
                //vol: 0.8,
                voice: voice_settings.voice,
                isMarkdown: true,
                played: undefined,
                relatedArticles: data.chatbot?.relatedtreatments,
                contexts: data.chatbot.contexts
                  ? processContexts(data.chatbot.contexts)
                  : [],
                isForm: data.chatbot?.isForm,
                formName: data.chatbot.formName,
                logKey,
                historyKey,
              },
            ]);
          }

          setResponseAdded(true);
        }
        if (queryParams.has('search')) {
          queryParams.delete('search');
          history.replace({
            search: queryParams.toString(),
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
        ChatbotRan(!isLogin ? 'not logged-in' : 'logged-in');
      },
      onError(errors) {
        setLoading(false);
        setInputMessage('');
      },
    },
  );

  useEffectOnlyOnce(() => {
    getlogkey();
  });

  const [getlogkey] = useLazyQuery<ChatBotHistoryKeyQuery>(ChatBot_LogKey, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setHistoryKey(data.getHistoryKey);
    },
  });

  const { loading: loadingd, data: historydata } = useQuery<
    ChatBotHistoryQuery,
    QueryChatBotHistoryArgs
  >(ChatBot_History, {
    //skip: !params,
    variables: {
      params,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data != null && data.history != null) {
        const tempMessages: React.SetStateAction<any[]> = [];
        var temporarySurveys: any[] = [];

        data.history.forEach((msg: any) => {
          if (msg.role == 'user') {
            tempMessages.push({ text: msg.content, type: 'user' });
          } else {
            tempMessages.push({
              text: msg.content,
              type: 'bot',
              responseId: msg.responseId,
              like: msg.like,
              comment: msg.comment,
              history: true,
              isMarkdown: true,
              pitch: voice_settings.pitch,
              rate: voice_settings.rate,
              //vol: 0.8,
              voice: voice_settings.voice,
              settings: false,
              played: undefined,
              relatedArticles:
                msg.relatedArticles?.length > 0 ? msg.relatedArticles : [],
            });
          }
        });

        if (messages.length == 0 && inputMessage) {
          tempMessages.push({ text: inputMessage, type: 'user' });
        }
        if (data.history?.length > 0) {
          setSearchResults(
            data.history[data.history?.length - 1].relatedArticles,
          );
          data.history[data.history?.length - 1].relatedArticles.forEach(
            (rt: { survey: any[] | null }) => {
              if (
                (!isLogin && rt?.survey != null && rt?.survey.length > 0) ||
                (rt?.survey != null &&
                  rt?.survey.length > 0 &&
                  isLogin &&
                  (isPatient || isUser))
              ) {
                rt.survey.forEach(survey => {
                  temporarySurveys.push({
                    surveyId: survey._id,
                    name: survey.name,
                    slug: survey.slug,
                    survey: survey.surveyData,
                  });
                });
              }
            },
          );
        }
        temporarySurveys = getAssignedSurveys(temporarySurveys);
        if (temporarySurveys.length > 0) {
          tempMessages.push({
            text: '',
            type: 'bot',
            surveys: temporarySurveys,
          });
        }
        setResponseAdded(true);
        setMessages(tempMessages);
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
  });

  function getAssignedSurveys(temporarySurveys: any) {
    if (isLogin) {
      var assignedSurveys = JSON.parse(
        getLocalStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
    } else {
      var assignedSurveys = JSON.parse(
        getSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
    }
    if (assignedSurveys != null && assignedSurveys.length > 0) {
      assignedSurveys.filter((survey: any) => {
        if (new Date() < new Date(survey.expiredDate))
          temporarySurveys.push(survey);
      });
      setAssignedSurvey(true);
      if (isLogin) {
        setDataLocalStorage(
          KEY_LOCAL_ASSIGNED_SURVEYS,
          JSON.stringify(assignedSurveys),
        );
      } else {
        setDataSessionStorage(
          KEY_LOCAL_ASSIGNED_SURVEYS,
          JSON.stringify(assignedSurveys),
        );
      }
    }
    return temporarySurveys;
  }

  useEffect(() => {
    if (message) handleUserInput(message, true);
  }, [location, message]);

  useEffect(() => {}, [messages]);

  useEffect(() => {
    if (chatBodyRef && chatBodyRef.current && responseAdded) scrollToBottom();
  }, [messages]);

  useEffectOnlyOnce(() => {
    if (chatBodyRef && chatBodyRef.current) scrollToBottom();
  });

  const scrollToBottom = () => {
    if (isMobile) {
      chatBodyRef!.current!.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    } else {
      chatBodyRef!.current!.scrollTop = chatBodyRef!.current!.scrollHeight;
    }
  };

  useEffect(() => {
    if (historyKey && message) handleUserInput();
  }, [historyKey]);

  // Function to handle user input
  const handleUserInput = (message?: any, flg?: boolean) => {
    // getSearchResults({
    //   variables: { params: { query: message, isSearch: true } },
    // });

    if (message != '' && flg != undefined) {
      setLoading(true);
      setInputMessage(message);
    } else {
      if (
        inputMessage.trim() === '' ||
        (message == undefined && message == '')
      ) {
        setLoading(false);
        return;
      }
    }
    setLoading(true);
    // Add the user's message to the chat
    setMessages([
      ...messages,
      { text: flg != undefined ? message : inputMessage, type: 'user' },
    ]);
    var treatmentsAssigned = JSON.parse(
      getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)! as string,
    );
    var organization = getSessionStorage(KEY_SESSION_ORGANIZATION_ID)!;
    let params: QueryChatBotArgs['params'] = {
      query: flg != undefined ? message : inputMessage,
    };
    if (treatmentsAssigned != null && treatmentsAssigned.length > 0) {
      params['treatmentAssignmentInput'] = treatmentsAssigned;
      params['nologkey'] = treatmentAssigneeEmail
        ? treatmentAssigneeEmail
        : logKey != null && logKey != 'null'
        ? logKey
        : historyKey;
      if (!treatmentAssigneeEmail && !logKey) {
        setLogKey(historyKey);
        setDataLocalStorage(
          KEY_LOCAL_NO_LOG_KEY,
          params.nologkey ? params.nologkey : historyKey,
        );
      }
    } else if (logKey != null && logKey != 'null') {
      params['nologkey'] = logKey;
    } else if (!isLogin && (logKey == null || logKey == 'null')) {
      params['nologkey'] = historyKey;
      setLogKey(historyKey);
      setDataLocalStorage(KEY_LOCAL_NO_LOG_KEY, historyKey);
    }
    if (organization != null || organization != 'null' || organization != '') {
      params['organization'] = organization;
    }

    if (treatmentContextFilteredIds.length > 0) {
      params['contextTreatments'] = treatmentContextFilteredIds;
      params['contextRequired'] = true;
    }

    const isForm = Boolean(JSON.parse(getLocalStorage(SHOW_FORMS)! as string));

    params['formRequired'] = isForm;
    params['isOut'] = false;

    if ((!isLogin && params.nologkey) || isLogin) {
      chatbot({
        variables: {
          params,
        },
      });
    } else {
      return;
    }
  };

  const initiateSurvey = () => {
    if (inputMessage != '')
      setMessages([...messages, { text: inputMessage, type: 'user' }]);

    if (surveyInitiated == null) setSurveyInitiated(false);
  };

  return (
    <Box>
      <ChatGridStyled container spacing={2}>
        {!props.fromAnotherComp && (
          <Grid item xs={!isMobile ? 7 : 12}>
            {!laodingSearchResults ? (
              <Grid container spacing={2}>
                {dataSearchResults?.chatbot.relatedtreatments &&
                dataSearchResults?.chatbot.relatedtreatments.length > 0 ? (
                  dataSearchResults?.chatbot.relatedtreatments.map(
                    (result: any) => (
                      <Grid item xs={12} sm={6}>
                        <TreatmentCard
                          slug={result?.slug}
                          mainImage={result?.mainImage}
                          mainVideo={result?.mainVideo}
                          name={result?.name}
                          shortDescription={result?.shortDescription}
                          description=""
                          content=""
                          chatbot={true}
                          childrenLength={4}
                        />
                      </Grid>
                    ),
                  )
                ) : (
                  <NoDataPage marginTop={10} />
                )}
              </Grid>
            ) : (
              <>{Array(3).fill(<LoadingTreatment marginTop={0} />)}</>
            )}
          </Grid>
        )}
        {!isMobile ? (
          props.fromAnotherComp ? (
            <>
              <Box style={{ width: '100%', margin: '10px' }}>
                {props.relatedArticles && props.relatedArticles.length > 0 && (
                  <Box>
                    <Typography variant="h6">Further Reading</Typography>
                  </Box>
                )}
                {props.relatedArticles && props.relatedArticles.length > 0 && (
                  <Box style={{ height: '84vh', overflowY: 'scroll' }}>
                    {props.relatedArticles.slice(0, 4).map((subtreatment: any) => (
                      <>
                        {/* {!props.subSectionIds.includes(subtreatment.subId) && ( */}
                        <Grid xs={12} onClick={handleScrollClick}>
                          <LinkRouterStyled
                            to={`/treatment/${subtreatment.slug}`}
                            className="rcmd"
                          >
                            <CardStyled>
                              <Box
                                position={'relative'}
                                top={0}
                                right={0}
                                left={0}
                                bottom={0}
                              >
                                <CardMedia
                                  component="img"
                                  image={renderMedia(subtreatment.mainImage)} // Replace with your thumbnail path
                                  alt="Thumbnail"
                                  style={cardMediaStyling}
                                />
                              </Box>
                              <CardContentStyled>
                                <TreatmentTitleStyled variant="body2">
                                  {subtreatment.name}
                                </TreatmentTitleStyled>
                                <Tooltip
                                  title={
                                    <Box
                                      padding={'5px'}
                                      fontSize={13}
                                      lineHeight={1.3}
                                    >
                                      {subtreatment?.shortDescription}
                                    </Box>
                                  }
                                  placement="right-end"
                                  arrow
                                >
                                  <ShortDescriptionStyled
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {subtreatment?.shortDescription}
                                  </ShortDescriptionStyled>
                                </Tooltip>
                              </CardContentStyled>
                            </CardStyled>
                          </LinkRouterStyled>
                        </Grid>
                        {/* // )} */}
                      </>
                    ))}
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <WebChatbotGrid item xs={5}>
              {chatBot && (
                <Chatbot
                  assignedSurvey={assignedSurvey}
                  setAssignedSurvey={setAssignedSurvey}
                  loading={loading}
                  messages={messages}
                  setMessages={setMessages}
                  chatBodyRef={chatBodyRef}
                  inputMessage={inputMessage}
                  setInputMessage={setInputMessage}
                  handleUserInput={handleUserInput}
                  selectedSlug={selectedSlug}
                  setSelectedSlug={setSelectedSlug}
                  surveyInitiated={surveyInitiated}
                  initiateSurvey={initiateSurvey}
                  setSurveyInitiated={setSurveyInitiated}
                  selectedSurvey={selectedSurvey}
                  setSelectedSurvey={setSelectedSurvey}
                  scrollToBottom={scrollToBottom}
                  setResponseAdded={setResponseAdded}
                  setTreatmentContextFilteredIds={
                    setTreatmentContextFilteredIds
                  }
                />
              )}
            </WebChatbotGrid>
          )
        ) : (
          <Box>
            <FloatingChatStyled
              color="primary"
              aria-label="add"
              onClick={() => setDisplayChatBot(!displayChatbot)}
            >
              <Chat />
            </FloatingChatStyled>
            {displayChatbot && (
              <MobileChatBot
                assignedSurvey={assignedSurvey}
                setAssignedSurvey={setAssignedSurvey}
                loading={loading}
                messages={messages}
                setMessages={setMessages}
                chatBodyRef={chatBodyRef}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                handleUserInput={handleUserInput}
                selectedSlug={selectedSlug}
                setSelectedSlug={setSelectedSlug}
                surveyInitiated={surveyInitiated}
                setSurveyInitiated={setSurveyInitiated}
                selectedSurvey={selectedSurvey}
                setSelectedSurvey={setSelectedSurvey}
                initiateSurvey={initiateSurvey}
                displayChatbot={displayChatbot}
                setDisplayChatBot={setDisplayChatBot}
                fromAnotherComp={props.fromAnotherComp}
                setResponseAdded={setResponseAdded}
              />
            )}
          </Box>
        )}
      </ChatGridStyled>
    </Box>
  );
};
export default ChatResults;
