import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  CHECK_HIGHLIGHT,
  CREATE_QR_CODE_FLOW,
  GET_TREATMENTS_HOME_PAGE,
  USER_UPDATE_PROFILE,
} from 'gql';
import {
  CheckHightLightQuery,
  CheckHightLightQueryVariables,
  CreateQRCodeFlowMutation,
  CreateQRCodeFlowMutationVariables,
  FlowFragment,
  GetTreatmentsHomePageQuery,
  GetTreatmentsHomePageQueryVariables,
  HomePageConfig,
  UserUpdateMutation,
  UserUpdateMutationVariables,
} from 'types.d';
import {
  ErrorPage,
  QrCodeIcon,
  ScrollToTop,
  TextfieldSelect,
} from 'components';
import { CheckHightLightContext, LayoutContext } from 'share/context';
import {
  useCustomPermission,
  useCheckLogin,
  useUpdateMeClient,
  useMutationCustom,
  useLogout,
  useToogleDialog,
} from 'hooks';
import HomeUser from './components/HomeUser';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import TreatmentCard from './components/TreatmentCard';
import { Skeleton } from '@material-ui/lab';
import { styled } from '@material-ui/core/styles';
import { useUpdateHomepageClient } from 'hooks/useUpdateHomepage';
import { HOME_PAGE_CONFIG } from 'CONST';
import TreatmentsComponent from './components/Treatments';
import { cleanObject, handleError } from 'share/utils';
import { Loading } from './components/Loading';
import { PaperBorder } from 'share/component_css';
import ViewQrCode from 'modules/treatment_flows/components/DialogViewQrCode';
import { useSnackbar } from 'notistack';
import { Logout } from 'mdi-material-ui';
import DialogNavigatorFlow from 'modules/treatment_flows/components/NavigatorFlow/DialogNavigatorFlow';

type Props = {
  url?: any;
};

export const SectionHeadingStyled = styled(Typography)(({ theme }) => ({
  margin: '10px 0px',
  fontWeight: 500,
}));

export const defaultVariables = {
  limit: 5,
  page: 1,
  isRoot: true,
  isHideOnHomePage: false,
  sortByOrder: { orderSetting: 1 },
};

export const Home: React.FC<Props> = ({ url }) => {
  const { idMe } = useCustomPermission();

  const { isUser, isAdmin, isNavigator } = useCustomPermission();

  const { homepageClient } = useUpdateHomepageClient();

  const { checkUserForMenu } = useCustomPermission();

  const { enqueueSnackbar } = useSnackbar();

  const layoutContext = useContext(LayoutContext);

  const isLogin = useCheckLogin();

  const { logoutLoading, handleLogout } = useLogout();

  const [hasMore, setHasMore] = useState(false);

  const [treatments, setTreatments] = useState<any>([]);

  const [isReset, setIsReset] = useState(false);

  const [isSearch, setIsSearch] = useState(false);

  const [loadingData, setLoadingData] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const [openDialog, setOpenDialog] = useState('');

  const [flowView, setFlowView] = useToogleDialog();

  const [flow, setFlow] = useState<FlowFragment | null>(null);

  const [menuItems, setMenuItems] = useState(
    HOME_PAGE_CONFIG?.map(item => {
      if (item.value != HomePageConfig.Home) {
        return {
          ...item,
          checked: checkUserForMenu(item.value),
        };
      } else {
        return item;
      }
    }),
  );

  const { data: dataCheckHighLight } = useQuery<
    CheckHightLightQuery,
    CheckHightLightQueryVariables
  >(CHECK_HIGHLIGHT, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    qrCodeCreate,
    { loading: loadingQrCodeCreate, error },
  ] = useMutationCustom<
    CreateQRCodeFlowMutation,
    CreateQRCodeFlowMutationVariables
  >({
    api: CREATE_QR_CODE_FLOW,
    textSuccess: 'QR Code created successfully',
    callbackSuccess: () => {
      setOpenDialog('qrCode');
    },
    callbackError: () => {
      const arrError = handleError(error?.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const { handleUpdateMeClient, meClient } = useUpdateMeClient();

  const [dataLoadType, setDataLoadType] = useState<HomePageConfig>(
    meClient?.setting?.homePage?.treatmentType
      ? meClient?.setting?.homePage?.treatmentType
      : HomePageConfig.Home,
  );

  const renderParamByType = useCallback(
    (type: string) => {
      switch (type) {
        case HomePageConfig.PrivateTreatments:
          return {
            isPrivate: true,
          };
        case HomePageConfig.PublicTreatments:
          return {
            isPrivate: false,
          };
        case HomePageConfig.MyTreatment:
          return {
            isOwner: true,
          };
        case HomePageConfig.MyFavoriteTreatments:
          return {
            favorites: idMe,
          };
        default:
          return {};
      }
    },
    [idMe],
  );

  const handleChangeType = (e: any) => {
    setDataLoadType(e.target.value);
    onSubmit(e);
  };

  const resetFilter = (value?: any) => {
    setDataLoadType(
      value
        ? HomePageConfig.Home
        : meClient?.setting?.homePage?.treatmentType
        ? meClient?.setting?.homePage?.treatmentType
        : HomePageConfig.Home,
    );
    setIsSearch(false);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (e.target.value == HomePageConfig.Home) {
      resetFilter(e.target.value);
      return;
    }
    let params: GetTreatmentsHomePageQueryVariables['params'] = {
      ...defaultVariables,
      ...renderParamByType(e.target.value),
      name: null,
    };
    setTreatments([]);
    setLoadingData(true);
    setIsSearch(false);
    fetch({
      variables: {
        params,
      },
    });
  };

  const [userUpdate, { loading }] = useMutationCustom<
    UserUpdateMutation,
    UserUpdateMutationVariables
  >({
    api: USER_UPDATE_PROFILE,
    textSuccess: 'Configs updated successfully',
    callbackSuccess: data => {
      handleUpdateMeClient({
        ...data.updateUserProfile,
      });
    },
  });

  const [fetch, { fetchMore }] = useLazyQuery<
    GetTreatmentsHomePageQuery,
    GetTreatmentsHomePageQueryVariables
  >(GET_TREATMENTS_HOME_PAGE, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data.getTreatments.nodes.length > 0) {
        if (treatments.length != 0) setTreatments([]);
        setIsReset(false);
        var item;
        if (treatments.length > 0) {
          const filteredNodes = data.getTreatments.nodes.filter(node => {
            // Check if the node exists in treatments
            const nodeExists = treatments.some(
              (treatment: { children: any[] }) =>
                treatment.children.some(child => child._id === node._id),
            );
            // Return true if the node does not exist in treatments
            return !nodeExists;
          });
          treatments[0].children.push(...filteredNodes);
        } else {
          item = {
            name: '',
            children: data.getTreatments.nodes,
          };
          treatments.push(item);
        }
        setTreatments(treatments);
        setHasMore(
          data!.getTreatments!.meta!.total >
            [...data!.getTreatments!.nodes].length,
        );
      }
      setIsSearch(true);
      setLoadingData(false);
    },
    onError(error) {
      console.log(error);
    },
  });

  useEffect(() => {
    if (isLogin && !loaded && (isAdmin || isNavigator)) {
      setLoadingData(true);
      let params: GetTreatmentsHomePageQueryVariables['params'] = {
        ...defaultVariables,
        ...renderParamByType(dataLoadType),
        name: null,
      };
      fetch({
        variables: {
          params,
        },
      });
      setLoaded(true);
    }
  }, [dataLoadType]);

  function handleCheckboxChange(e: any, value: HomePageConfig): void {
    e.stopPropagation();
    const newItems = menuItems.map(item =>
      item.value === value
        ? {
            ...item,
            checked: e.target.checked,
          }
        : item,
    );
    setMenuItems(newItems);
    if (meClient.setting?.analyticDashboard)
      meClient.setting.analyticDashboard = cleanObject(
        meClient.setting?.analyticDashboard,
        '__typename',
      );

    if (meClient.setting?.homePage)
      meClient.setting.homePage = cleanObject(
        meClient.setting.homePage,
        '__typename',
      );

    if (meClient.setting?.patientDashboard)
      meClient.setting.patientDashboard = cleanObject(
        meClient.setting.patientDashboard,
        '__typename',
      );

    if (
      meClient.setting?.leftDrawer != null &&
      meClient.setting?.leftDrawer.length > 0
    ) {
      meClient.setting.leftDrawer = meClient.setting.leftDrawer?.map(
        ({
          __typename,
          ...rest
        }: {
          __typename: string;
          [key: string]: any;
        }): any => rest,
      );

      let exist = meClient.setting?.leftDrawer.findIndex((item: any) => {
        return item.treatmentType === value;
      });
      if (exist > -1) {
        meClient.setting.leftDrawer[exist].showInDrawer = e.target.checked;
      } else {
        meClient.setting?.leftDrawer?.push({
          treatmentType: value,
          showInDrawer: e.target.checked,
        });
      }
    } else if (
      !meClient.setting &&
      (meClient.setting?.leftDrawer == null ||
        meClient.setting?.leftDrawer?.length == 0)
    ) {
      meClient.setting = {};
      meClient.setting['leftDrawer'] = [
        {
          treatmentType: value,
          showInDrawer: e.target.checked,
        },
      ];
    } else if (
      meClient.setting &&
      (meClient.setting?.leftDrawer == null ||
        meClient.setting?.leftDrawer?.length == 0)
    ) {
      meClient.setting['leftDrawer'] = [
        {
          treatmentType: value,
          showInDrawer: e.target.checked,
        },
      ];
    }
    userUpdate({
      variables: {
        params: {
          firstName: meClient.firstName,
          lastName: meClient.lastName,
          setting: {
            analyticDashboard: meClient.setting.analyticDashboard,
            patientDashboard: meClient.setting.patientDashboard,
            homePage: meClient.setting.homePage,
            perPage: meClient.setting.perPage,
            leftDrawer: meClient.setting?.leftDrawer,
          },
        },
      },
    });
  }

  const getMoreData = useCallback(
    async (
      dataFilter: {
        name: string;
        type?: HomePageConfig;
      },
      page: number,
      reset?: boolean,
      type?: string,
    ) => {
      if (reset) {
        setIsReset(true);
      }
      setLoadingData(true);
      let params: GetTreatmentsHomePageQueryVariables['params'] = {
        ...defaultVariables,
        ...renderParamByType(type || HomePageConfig.AllTreatment),
        name: null,
        page,
      };
      await fetchMore({
        variables: {
          params,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getTreatments: {
              ...prev.getTreatments,
              nodes: reset
                ? [...fetchMoreResult!.getTreatments!.nodes!]
                : [
                    ...prev!.getTreatments!.nodes!,
                    ...fetchMoreResult!.getTreatments!.nodes!,
                  ],
              meta: {
                ...prev!.getTreatments!.meta!,
                ...fetchMoreResult!.getTreatments!.meta!,
              },
            },
          };
        },
      });
    },
    [fetchMore, renderParamByType],
  );

  const handleQrCode = (item: any) => {
    if (item?.qrCode) {
      setOpenDialog('qrCode');
    } else {
      qrCodeCreate({
        variables: {
          params: {
            _id: item?._id,
          },
        },
      });
    }
  };

  const handleOpenFlow = (index: number) => {
    setFlowView(true);
    setFlow(homepageClient.favoriteFlows[index]);
  };

  return (
    <>
      {flow && flowView && (
        <DialogNavigatorFlow
          open={flowView}
          toogleDialog={setFlowView}
          flow={flow}
        />
      )}
      {(isAdmin || isNavigator) && (
        <form noValidate onSubmit={onSubmit}>
          <Box
            display="grid"
            gridTemplateColumns="250px"
            gridGap={12}
            justifyContent="end"
          >
            <TextfieldSelect
              label="View"
              name="type"
              small
              callbackChangeValue={e => handleChangeType(e)}
              value={dataLoadType}
            >
              {menuItems?.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.checked != null ? (
                    <FormControlLabel
                      control={
                        <Tooltip title={`Show in Menu`}>
                          <Checkbox
                            checked={item.checked}
                            onClick={e => handleCheckboxChange(e, item.value)}
                          />
                        </Tooltip>
                      }
                      label={item.title}
                    />
                  ) : (
                    <FormControlLabel
                      control={<></>}
                      style={{
                        marginLeft: '28px',
                      }}
                      label={item.title}
                    />
                  )}
                </MenuItem>
              ))}
            </TextfieldSelect>
          </Box>
        </form>
      )}
      {isNavigator &&
        homepageClient &&
        homepageClient?.favoriteFlows &&
        homepageClient?.favoriteFlows.length > 0 && (
          <Box mb={3}>
            <SectionHeadingStyled variant="h5">
              Treatment Flows
            </SectionHeadingStyled>
            <Grid container spacing={3}>
              {homepageClient.favoriteFlows.map(
                (favorite: any, index: number) => (
                  <Grid item xs={12} md={3} lg={4}>
                    <PaperBorder>
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        paddingX={2}
                        paddingY={1}
                      >
                        <Typography variant="h6" gutterBottom>
                          {favorite?.name}
                        </Typography>
                        <Box>
                          <>
                            <Tooltip title="Open Flow" arrow>
                              <IconButton
                                onClick={() => {
                                  handleOpenFlow(index);
                                  // handleLogout(favorite?.urlSharing);
                                }}
                              >
                                <Logout />{' '}
                                {logoutLoading && (
                                  <CircularProgress
                                    className="ml-4"
                                    size={20}
                                    color="inherit"
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          </>
                          <>
                            <ViewQrCode
                              open={openDialog}
                              setOpenModal={setOpenDialog}
                              flow={favorite}
                            />
                            <Tooltip title="Share" arrow>
                              <IconButton
                                onClick={() => handleQrCode(favorite)}
                              >
                                <QrCodeIcon />{' '}
                                {loadingQrCodeCreate && (
                                  <CircularProgress
                                    className="ml-4"
                                    size={20}
                                    color="inherit"
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          </>
                        </Box>
                      </Box>
                    </PaperBorder>
                  </Grid>
                ),
              )}
            </Grid>
          </Box>
        )}
      {(!isSearch && !loadingData) || dataLoadType === HomePageConfig.Home ? (
        homepageClient ? (
          homepageClient?.dynamicTreatments &&
          homepageClient?.dynamicTreatments.length > 0 ? (
            <Box>
              {homepageClient?.dynamicTreatments.map(
                (treatment: any, index: number) => (
                  <Box key={index} mb={3}>
                    <SectionHeadingStyled variant="h5">
                      {treatment?.title}
                    </SectionHeadingStyled>
                    <Grid container spacing={2}>
                      {treatment.children.map(
                        (card: any, ind: React.Key | undefined) => (
                          <Grid
                            key={ind}
                            item
                            xs={12}
                            sm={treatment.children.length < 2 ? 12 : 6}
                            md={
                              treatment.children.length === 1
                                ? 12
                                : treatment.children.length === 2
                                ? 6
                                : layoutContext?.chatbotDrawer
                                ? 6
                                : 4
                            }
                            lg={
                              treatment.children.length === 1
                                ? 12
                                : treatment.children.length === 2
                                ? 6
                                : treatment.children.length === 3
                                ? 4
                                : layoutContext?.chatbotDrawer
                                ? 4
                                : 3
                            }
                          >
                            <TreatmentCard
                              slug={card?.treatmentRef?.slug}
                              mainImage={card?.treatmentRef?.mainImage}
                              mainVideo={card?.treatmentRef?.mainVideo}
                              name={card?.treatmentRef?.name}
                              shortDescription={
                                card?.treatmentRef?.shortDescription
                              }
                              description={card?.treatmentRef?.description}
                              content={card?.treatmentRef?.content}
                              subsections={card?.treatmentRef?.subSections}
                              bannerImage={card?.treatmentRef?.bannerImage}
                              childrenLength={treatment?.children.length}
                              isSingleImage={treatment?.children?.length === 1}
                            />
                          </Grid>
                        ),
                      )}
                    </Grid>
                  </Box>
                ),
              )}
            </Box>
          ) : !url ? (
            <Box my={5}>
              <CheckHightLightContext.Provider
                value={{
                  stageHightLight:
                    dataCheckHighLight?.checkHightLight?.map(
                      item => item.linkTo?._id,
                    ) || [],
                }}
              >
                {(isUser || !isLogin) && <HomeUser />}
                <ScrollToTop />
              </CheckHightLightContext.Provider>
            </Box>
          ) : (
            <ErrorPage error="404" />
          )
        ) : (
          <Skeleton width={'100%'} variant="rect" height={300} />
        )
      ) : isSearch && (isAdmin || isNavigator) ? (
        treatments.length > 0 ? (
          <>
            <TreatmentsComponent
              setHasMore={setHasMore}
              getMoreData={getMoreData}
              hasMore={hasMore}
              isReset={isReset}
              items={treatments}
              dataLoadType={dataLoadType}
            />
            {loadingData && <Loading />}
          </>
        ) : (
          <ErrorPage error="404" />
        )
      ) : loadingData ? (
        <div
          style={{
            marginTop: '20px',
          }}
        >
          <Loading />
        </div>
      ) : (
        <Skeleton width={'100%'} variant="rect" height={300} />
      )}
    </>
  );
};
export default Home;
