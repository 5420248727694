import React, { useState } from 'react';
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
  Button,
} from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { ArrowRightAlt } from '@material-ui/icons';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  CHECK_USER_IN_SYSYEM,
  CREATE_NEW_PATIENT,
  GENERATE_AND_SEND_OTP_FOR_NON_USER,
  SUBMIT_FLOW_DATA,
  VERIFY_OTP_FOR_NON_USER,
} from 'gql';
import { useLogout, useToogleDialog } from 'hooks';
import { GridSurveyStyled, MediaStyled } from 'share/component_css';
import { handleError } from 'share/utils';

import {
  CheckUserInSystemQuery,
  CheckUserInSystemQueryVariables,
  CreateNewPatientInput,
  CreateNewPatientMutation,
  CreateNewPatientMutationVariables,
  FlowFragment,
  GenerateAndSendOTPForNonUserMutation,
  GenerateAndSendOTPForNonUserMutationVariables,
  SubmitFlowDataMutation,
  SubmitFlowDataMutationVariables,
  TreatmentType,
  VerifyOtpForNonUserMutation,
  VerifyOtpForNonUserMutationVariables,
} from 'types.d';
import { TreatmentFlowContext } from 'share/context';

import { BackdropComponent, ButtonLoading } from 'components';
import MediaTreatment from 'components/MediaTreatment';
import FlowSurveyDisplay from '../FlowSurveyDisplay';

import { ContactInformation } from './ContactInformationForm';
import { TreatmentHeadingStyled } from 'modules/treatments/components/Treatment';
import DialogOtpConfirmation from './DialogOtpConfirmation';

export type treatmentSurveyDataType = {
  [key: string]: { [key: string]: string };
};

type Props = {
  flow: FlowFragment;
  handleCompleteTreatmentFlow: (type: boolean) => void;
};

export const NavigatorTreatmentFlow: React.FC<Props> = ({
  flow,
  handleCompleteTreatmentFlow,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleLogout } = useLogout();

  const [activeStep, setActiveStep] = useState(0);
  const [navigatorFlow, setNavigatorFlow] = useToogleDialog();
  const [otpDialog, setOtpDialog] = useToogleDialog();
  const [patientInfo, setPatientInfo] = useState<any>(null);
  const [treatmentSurveyData, setTreatmentSurveyData] = useState<
    treatmentSurveyDataType | undefined
  >(undefined);
  const [contactType, setContactType] = useState('email');

  // Mutations
  const [submitFlowData, { loading }] = useMutation<
    SubmitFlowDataMutation,
    SubmitFlowDataMutationVariables
  >(SUBMIT_FLOW_DATA, {
    onError: error => {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('Responses submitted successfully', {
        variant: 'success',
      });

      if (otpDialog) handleLogout('/home');
      handleCompleteTreatmentFlow(true);
    },
  });

  const [createPatient, { loading: loadingCreatePatient }] = useMutation<
    CreateNewPatientMutation,
    CreateNewPatientMutationVariables
  >(CREATE_NEW_PATIENT, {
    onCompleted: data => {
      const { userExists, data: newData, patient } = data.createNewPatient;
      if (userExists) handleSubmitFlow(userExists[0]._id, contactType);
      else if (newData) handleSubmitFlow(newData[0].idUser, contactType);
      else if (patient) handleSubmitFlow(patient._id, contactType);
    },
    onError: error => {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const [generateOTP, { loading: loadingGenerateOTP }] = useMutation<
    GenerateAndSendOTPForNonUserMutation,
    GenerateAndSendOTPForNonUserMutationVariables
  >(GENERATE_AND_SEND_OTP_FOR_NON_USER, {
    onError: error => {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('OTP sent successfully', { variant: 'success' });
      setOtpDialog(true);
    },
  });

  const [checkUserInSystem] = useLazyQuery<
    CheckUserInSystemQuery,
    CheckUserInSystemQueryVariables
  >(CHECK_USER_IN_SYSYEM, {
    onCompleted: data => {
      const exists = data.checkUserInSystem?.isValid;
      if (exists) {
        const { firstName, lastName, email, dob, phones, gender } = patientInfo;
        createPatient({
          variables: {
            params: {
              firstName,
              lastName,
              email,
              dob,
              phones,
              gender,
            } as CreateNewPatientInput,
          },
        });
      } else {
        handleLogout('');
        handleGenerateOtp();
      }
    },
    onError: error => {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const [verifyOTPNonUsers, { loading: loadingNonOTP }] = useMutation<
    VerifyOtpForNonUserMutation,
    VerifyOtpForNonUserMutationVariables
  >(VERIFY_OTP_FOR_NON_USER, {
    onError: error => {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('OTP verified successfully', {
        variant: 'success',
      });

      const treatmentIds = flow.references
        .filter((item: any) => item.type === TreatmentType.Treatment)
        .map((item: any) => item._id);

      submitFlowData({
        variables: {
          params: {
            treatments: treatmentIds,
            surveys: treatmentSurveyData,
            flowId: flow._id,
            contactType: contactType,
          },
        },
      });
    },
  });

  // Handlers
  const handleNext = () => {
    if (activeStep === flow.references.length - 1) {
      setNavigatorFlow(true);
    }
    setActiveStep(prev => prev + 1);
  };

  const handleBack = () => {
    if (navigatorFlow) setNavigatorFlow(false);

    setActiveStep(prev => prev - 1);
  };

  const handleSubmitFlow = (patientId: string, contactType: string) => {
    setContactType(contactType);

    submitFlowData({
      variables: {
        params: {
          treatments: flow.references.map((item: any) => item._id),
          surveys: treatmentSurveyData,
          flowId: flow._id,
          patientId,
          contactType: contactType,
        },
      },
    });
  };

  const handleCreatePatient = (paramsCreate: any, create: string) => {
    if (create === 'yes') {
      setPatientInfo(paramsCreate);
      checkUserInSystem({
        variables: {
          params: {
            phoneNumber: paramsCreate.phones,
            email: paramsCreate.email,
          },
        },
      });
    } else {
      const { firstName, lastName, email, dob, phones, gender } = paramsCreate;
      createPatient({
        variables: {
          params: {
            firstName,
            lastName,
            email,
            dob,
            phones,
            gender,
          } as CreateNewPatientInput,
        },
      });
    }
  };

  const handleDialogClose = () => {
    setOtpDialog(false);
  };

  const handleGenerateOtp = () => {
    generateOTP({
      variables: {
        params: {
          email: patientInfo.email,
          phoneNumber: patientInfo.phones,
        },
      },
    });
  };

  const handleVerifyOtpForNonUsers = (values: string) => {
    verifyOTPNonUsers({
      variables: {
        params: {
          firstName: patientInfo?.firstName,
          middleName: '-',
          lastName: patientInfo?.lastName,
          email: patientInfo?.email,
          otp: values,
          phone: patientInfo?.phoneNumber ? `+${patientInfo?.phoneNumber}` : ``,
          flowId: flow._id,
          password: patientInfo?.password,
        },
      },
    });
  };

  return (
    <TreatmentFlowContext.Provider value={[]}>
      <BackdropComponent loading={loading} />

      {otpDialog && (
        <DialogOtpConfirmation
          open={otpDialog}
          loading={loadingNonOTP || loadingGenerateOTP}
          onClose={handleDialogClose}
          handleGenerateOtp={handleGenerateOtp}
          handleVerifyOtpForNonUsers={handleVerifyOtpForNonUsers}
        />
      )}

      <Grid container>
        <Grid item xs={12} md={12}>
          <Box margin={'0 auto'} maxWidth={900}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {flow.references.map((treatment, index: number) => (
                <Step key={treatment._id}>
                  <StepLabel
                    optional={
                      index === flow.references.length - 1 ? (
                        <Typography variant="caption">Last Step</Typography>
                      ) : null
                    }
                  >
                    <TreatmentHeadingStyled
                      variantMapping={{ h5: 'h2' }}
                      variant="h5"
                    >
                      {treatment.name}
                    </TreatmentHeadingStyled>
                  </StepLabel>
                  <StepContent>
                    {treatment.type === TreatmentType.Treatment && (
                      <>
                        <Box my={1}>
                          <Typography color="textSecondary" variant="body1">
                            {treatment!.description!}
                          </Typography>
                        </Box>
                        {!treatment.hideMainMedia && (
                          <MediaStyled>
                            <MediaTreatment
                              mainVideo={treatment!.mainVideo!}
                              mainImage={treatment!.mainImage!}
                            />
                          </MediaStyled>
                        )}
                        <ReactMarkdown
                          linkTarget="_blank"
                          source={treatment!.content!}
                        />
                      </>
                    )}
                    {treatment.type === TreatmentType.Survey && (
                      <GridSurveyStyled xs={12} item>
                        <FlowSurveyDisplay
                          key={treatment._id}
                          idSurvey={treatment._id}
                          surveyData={treatment.surveyData as string}
                          handleNext={handleNext}
                          handleBack={handleBack}
                          activeStep={activeStep}
                          treatmentSurveyData={treatmentSurveyData}
                          setTreatmentSurveyData={setTreatmentSurveyData}
                          isOpen={true}
                        />
                      </GridSurveyStyled>
                    )}
                    {treatment.type === TreatmentType.Treatment && (
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        textAlign={'center'}
                        marginTop={'20px'}
                      >
                        {activeStep !== 0 && (
                          <Box marginRight={'10px'}>
                            <Button onClick={handleBack}>Back</Button>
                          </Box>
                        )}
                        {activeStep === flow.references.length - 1 ? (
                          <ButtonLoading
                            key={
                              activeStep === flow.references.length - 1
                                ? 'final-step'
                                : 'other-steps'
                            }
                            text={'Continue'}
                            loading={loading}
                            callbackClick={handleNext}
                          />
                        ) : (
                          <ButtonLoading
                            text="Continue"
                            loading={loading}
                            callbackClick={handleNext}
                          />
                        )}
                      </Box>
                    )}
                  </StepContent>
                </Step>
              ))}
            </Stepper>

            {navigatorFlow && (
              <ContactInformation
                handleBack={handleBack}
                onClose={() => {
                  setNavigatorFlow(false);
                  setActiveStep(flow.references.length - 1);
                }}
                handleCreatePatient={handleCreatePatient}
                handleSubmitFlow={handleSubmitFlow}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </TreatmentFlowContext.Provider>
  );
};

export default NavigatorTreatmentFlow;
