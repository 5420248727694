import React, { useState } from 'react';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  DialogContent,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { FlowFragment } from 'types.d';
import NavigatorTreatmentFlow from './NavigatorTreatmentFlow';
import { TypographyBold } from 'share/component_css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  flow: FlowFragment;
};

export const DialogNavigatorFlow: React.FC<Props> = ({
  open,
  toogleDialog,
  flow,
}) => {
  const [processComplete, setProcessComplete] = useState(false);

  const handleCancel = () => {
    toogleDialog(false);
  };

  const handleCompleteTreatmentFlow = () => {
    setProcessComplete(true);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleCancel}
        TransitionComponent={Transition}
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCancel}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ marginLeft: '8px', flex: 1 }}>
              {flow?.name}
            </Typography>
            <Button color="inherit" onClick={handleCancel}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent tabIndex={0}>
          {!processComplete ? (
            <NavigatorTreatmentFlow
              flow={flow}
              handleCompleteTreatmentFlow={handleCompleteTreatmentFlow}
            />
          ) : (
            <Box
              height="80vh"
              display="flex"
              justifyContent={'center'}
              alignItems={'center'}
            >
              <TypographyBold variant="h5">
                Thank you! Please hand over the tablet to navigator
              </TypographyBold>
            </Box>
          )}{' '}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(DialogNavigatorFlow);
