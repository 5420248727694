import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Survey, Model as SurveyModel } from 'survey-react';

import { treatmentSurveyDataType } from './TreatmentFlow';

const HideNavigationButtons = styled('div')(({ theme }) => ({
  '& .sv_nav': {
    display: 'none',
  },
}));

type Props = {
  surveyData: string;
  idSurvey: string;
  handleNext: () => void;
  handleBack: () => void;
  activeStep: number;
  treatmentSurveyData: treatmentSurveyDataType | undefined;
  setTreatmentSurveyData: React.Dispatch<
    React.SetStateAction<treatmentSurveyDataType | undefined>
  >;
  isOpen: Boolean;
};

export const FlowSurveyDisplay: React.FC<Props> = ({
  surveyData,
  idSurvey,
  handleNext,
  handleBack,
  activeStep,
  treatmentSurveyData,
  setTreatmentSurveyData,
  isOpen,
}) => {
  const survey = new SurveyModel(surveyData);

  useEffect(() => {
    if (treatmentSurveyData && treatmentSurveyData[idSurvey]) {
      survey.data = treatmentSurveyData[idSurvey];
    }
  }, [treatmentSurveyData, idSurvey, isOpen]);

  // Customizing the "Complete" button text
  survey.completeText = 'Continue';

  // Handle survey completion
  survey.onComplete.add(result => {
    setTreatmentSurveyData({
      ...treatmentSurveyData,
      [idSurvey]: survey.data,
    });
    handleNext();
  });

  const handleSurveyBack = () => {
    handleBack();
  };

  return (
    <>
      <HideNavigationButtons>
        <Survey model={survey} />
        <Box textAlign={'center'} marginTop={'20px'}>
          {activeStep !== 0 && (
            <Button onClick={handleSurveyBack} style={{ marginRight: '10px' }}>
              Back
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (survey.isLastPage) {
                survey.completeLastPage();
              } else {
                survey.nextPage();
              }
            }}
          >
            {survey.isLastPage ? 'Continue' : 'Next'}
          </Button>
        </Box>
      </HideNavigationButtons>
    </>
  );
};

export default React.memo(FlowSurveyDisplay);
