import React, { useCallback, useRef, useState } from 'react';
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import { Controller, FormContext, useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  CheckUserInSystemQuery,
  CheckUserInSystemQueryVariables,
  Gender,
  UserFragment,
} from 'types.d';
import { CHECK_USER_IN_SYSYEM } from 'gql';
import {
  convertDateWithoutUTC,
  handleError,
  trimObjectValue,
} from 'share/utils';
import { Phone, useFormPhone } from './FormPhoneFlow';
import { EMAIL_REGEX, LIST_GENDER, PASSWORD_REGEX } from 'CONST';
import { TypographyBold, TypographyItalic } from 'share/component_css';
import { ButtonLoading, TextfieldSelect } from 'components';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

export type FormData = {
  dob: string | null;
  firstName: string;
  lastName: string;
  phones: Phone[];
  gender: Gender | string;
  email?: string;
  password: string;
  confirmPassword: string;
};

type Props = {
  onClose: (status: boolean) => void;
  handleBack: () => void;
  handleCreatePatient: (patient: any, create: string) => void;
  handleSubmitFlow: (patientId: string, contactType: string) => void;
};

export const ContactInformation: React.FC<Props> = ({
  onClose,
  handleBack,
  handleCreatePatient,
  handleSubmitFlow,
}) => {
  const methods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      dob: null,
      phones: [],
    },
  });

  const { FormPhoneComponent, formPhoneComponentProps, remove } = useFormPhone<
    FormData
  >(methods);

  const { handleSubmit, errors, register, control, setValue, reset } = methods;

  const { enqueueSnackbar } = useSnackbar();
  const [emailValue, setEmailValue] = useState<UserFragment | null>(null);
  const [errorEmail, setErrorEmail] = useState('');
  const [typeEmail, setTypeEmail] = useState('personal');
  const [typeContact, setTypeContact] = useState('email');
  const [createAccount, setCreateAccount] = useState('');
  const [emailOptSelected, setEmailOptSelected] = useState(true);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [anchorEl, setAnchorEl] = useState<
    HTMLInputElement | HTMLTextAreaElement | null
  >(null);
  const [userExists, setUserExists] = useState<boolean | null>(null);
  const [patientExists, setPatientExists] = useState<boolean | null>(false);
  const [phones, setPhones] = useState<Phone[]>([]);

  const emailFromTextField = useRef<{
    value: UserFragment | null;
    hasValue: boolean;
  }>({ value: null, hasValue: false });

  const handleChangeTypeContact = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTypeContact((event.target as HTMLInputElement).value);
  };

  const handleChangeCreateAccount = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCreateAccount((event.target as HTMLInputElement).value);
  };

  const validationEmail = (email: any) => {
    if (!email) {
      setErrorEmail('This field is required');
      return;
    }
    if (email) {
      if (!EMAIL_REGEX.test(email as string)) {
        setErrorEmail('Email is invalid');
        return;
      }
    }

    setErrorEmail('');
  };

  const setDefaultEmpty = useCallback(() => {
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('dob', null);
    setValue('phones', []);
    setValue('gender', '');
    setValue('password', '');
    setValue('confirmPassword', '');
    if (formPhoneComponentProps.fields.length) {
      remove(Array.from(Array(formPhoneComponentProps.fields.length).keys()));
    }
  }, [formPhoneComponentProps.fields.length, remove, setValue]);

  const setDefaultValue = useCallback(
    (objUser: UserFragment | string) => {
      if (typeof objUser !== 'string') {
        reset({
          firstName: objUser.firstName as string,
          lastName: objUser.lastName as string,
          dob: objUser.dob,
          phones: objUser.phones || [],
        });
      } else {
        setDefaultEmpty();
      }
    },
    [reset],
  );

  const isObjectUser = (item: UserFragment | string | null) => {
    return (item as UserFragment)?.email !== undefined;
  };

  const onChangeEmail = (value: any, isValueFromTextField: boolean) => {
    if (isValueFromTextField || typeof value === 'string') {
      emailFromTextField.current = { value, hasValue: true };
      return;
    }
    emailFromTextField.current = { value: null, hasValue: false };
    const email = isObjectUser(value) ? (value as UserFragment).email : value;

    if (value) {
      if (value._id !== emailValue?._id) {
        setDefaultValue(value);
      }
    } else {
      setDefaultEmpty();
    }
    setEmailValue(value);
    validationEmail(email);
  };

  const setInformation = () => {
    if (emailFromTextField.current.hasValue) {
      validationEmail(emailFromTextField.current.value);
      setEmailValue(emailFromTextField.current.value);
      if (isObjectUser(emailValue)) {
        setDefaultEmpty();
      }
      return;
    }
  };

  const savePatient = (data: FormData) => {
    if (errorEmail.length === 0) {
      const dataTrim = trimObjectValue(data);
      const { ...restData } = dataTrim;
      const dataCreate = {
        ...restData,
        email: isObjectUser(emailValue)
          ? emailValue?.email
          : typeEmail === 'noEmail'
          ? null
          : emailValue,
        dob: convertDateWithoutUTC(restData.dob as string),
      };
      const { ...paramsCreate } = dataCreate;

      handleCreatePatient(paramsCreate, createAccount);
    }
  };

  const handleSave = handleSubmit(data => {
    const { phones, ...restValue } = data;

    if (phones && formPhoneComponentProps.indexDefault !== undefined) {
      phones[formPhoneComponentProps.indexDefault].default = true;
    }
    const paramsPhones = phones?.map(item => {
      return { ...item, phone: `+${item.phone}` };
    });
    if (Object.is(typeContact, 'email')) {
      if (emailValue) {
        savePatient({
          ...restValue,
          phones: paramsPhones,
        });
        return;
      }
      return;
    }

    savePatient({
      ...restValue,
      phones: paramsPhones,
    });
  });

  const handleOpenPopper = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCheckUser = () => {
    if (typeContact === 'email') {
      if (typeof emailValue === 'string') {
        checkUserInSystem({
          variables: {
            params: {
              email: emailValue,
            },
          },
        });
      }
    } else {
      const phones: Phone[] = formPhoneComponentProps.methods.watch('phones');

      if (phones.length > 0) {
        // Validate phones
        const emptyPhone = phones.find((phone: Phone) => !phone.phone.trim());
        if (emptyPhone) {
          // Throw an error if any phone is empty
          enqueueSnackbar('Empty phone numbers are not allowed', {
            variant: 'error',
          });
          console.error('Error: One or more phone numbers are empty.');

          return;
        }
        checkUserInSystem({
          variables: {
            params: {
              phoneNumber: `+${phones[0].phone}`,
            },
          },
        });
      }
    }
  };

  const [checkUserInSystem, { loading: loadingCheckUser }] = useLazyQuery<
    CheckUserInSystemQuery,
    CheckUserInSystemQueryVariables
  >(CHECK_USER_IN_SYSYEM, {
    onCompleted: data => {
      if (data.checkUserInSystem) {
        const {
          isValid,
          patient,
          patientId,
          patientInfo,
        } = data.checkUserInSystem;
        if (!isValid && patient && patientInfo) {
          setPatientExists(false);
          setUserExists(false);

          const { firstName, lastName, dob, gender } = patientInfo;

          reset({
            firstName: firstName || '',
            lastName: lastName || '',
            dob: dob || null,
            gender: gender || '',
            phones: patientInfo?.phones || [],
          });
        }
        if (!isValid && !patient) setUserExists(false);
        if (isValid && patient) handleSubmitFlow(patientId, typeContact);
      }
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  return (
    <FormContext {...methods}>
      <Grid container spacing={2} style={{ margin: '20px' }}>
        <Grid item xs={12}>
          <TypographyBold variant="h6">
            Choose how you'd like to be contacted
          </TypographyBold>
        </Grid>
        <Grid item xs={12}>
          <TypographyBold variant="subtitle1">
            Preferred Communication
          </TypographyBold>
          <TypographyItalic color="textSecondary" variant="caption">
            (Need to select at least one method for receiving treatment and
            surveys)
          </TypographyItalic>
        </Grid>

        <Grid item xs={12}>
          <RadioGroup
            row
            name="typeContact"
            value={typeContact}
            onChange={handleChangeTypeContact}
            className="ml-8"
          >
            <FormControlLabel
              value="email"
              control={<Radio color="primary" />}
              label="Email"
            />
            <FormControlLabel
              value="text"
              control={<Radio color="primary" />}
              label="Text"
            />
            <FormControlLabel
              value="call"
              control={<Radio color="primary" />}
              label="Call"
            />
          </RadioGroup>
        </Grid>

        {Object.is(typeContact, 'email') && (
          <>
            <Grid item xs={12}>
              <Autocomplete
                open={emailOptSelected}
                onOpen={() => setEmailOptSelected(true)}
                onClose={() => setEmailOptSelected(false)}
                renderOption={(option, { selected }) => {
                  const optionSet = option as UserFragment;
                  return (
                    <Box display="flex" flexDirection="column">
                      <Typography variant="h6" gutterBottom>
                        {optionSet.firstName +
                          (optionSet.middleName || '') +
                          ' ' +
                          optionSet.lastName}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <Typography
                          className="mr-8"
                          color="primary"
                          variant="subtitle1"
                        >
                          {optionSet.email}
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
                freeSolo
                options={[]}
                getOptionLabel={option =>
                  typeof option === 'string' ? option : option.email
                }
                onChange={(event: any, newValue: any) => {
                  onChangeEmail(newValue, false);
                }}
                value={emailValue}
                renderInput={params => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    {...{
                      InputProps: params.InputProps,
                      id: params.id,
                      disabled: params.disabled,
                      inputProps: params.inputProps,
                    }}
                    required
                    label="Email"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errorEmail}
                    helperText={errorEmail}
                    onBlur={setInformation}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeEmail(e.target.value, true);
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
        {(Object.is(typeContact, 'call') || Object.is(typeContact, 'text')) && (
          <>
            <Grid item xs={12} container spacing={2}>
              <FormPhoneComponent {...formPhoneComponentProps} />
            </Grid>
          </>
        )}

        {userExists === null && (
          <Box
            display="flex"
            alignItems={'center'}
            justifyContent="center"
            width={'100%'}
          >
            <ButtonLoading
              className="mt-24 mr-8"
              text="Back"
              color="default"
              callbackClick={handleBack}
            />
            <ButtonLoading
              className="mt-24"
              text={'Continue'}
              loading={loadingCheckUser}
              color="primary"
              callbackClick={() => {
                handleCheckUser();
              }}
            />
          </Box>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {userExists === false && (
        <>
          <Grid container spacing={2}>
            {/* {patientExists === null && ( */}
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ maxLength: 255 }}
                  label="First Name"
                  required
                  size="small"
                  name="firstName"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputRef={register({
                    validate: {
                      required: value =>
                        value.trim() !== '' || 'This field is required',
                    },
                  })}
                  error={!!errors.firstName}
                  helperText={errors.firstName ? errors.firstName.message : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputRef={register({
                    validate: {
                      required: value =>
                        value.trim() !== '' || 'This field is required',
                    },
                  })}
                  inputProps={{ maxLength: 255 }}
                  label="Last Name"
                  size="small"
                  required
                  name="lastName"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  error={!!errors.lastName}
                  helperText={errors.lastName ? errors.lastName.message : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <KeyboardDatePicker
                      required
                      placeholder="mm/dd/yyyy"
                      inputVariant="outlined"
                      label="Date of Birth"
                      format="MM/dd/yyyy"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      autoOk
                      error={!!errors?.dob}
                      helperText={
                        errors?.dob
                          ? errors?.dob?.types?.message || errors?.dob.message
                          : ''
                      }
                      keyboardIcon={<DateRangeIcon />}
                      onChange={function(
                        date: MaterialUiPickersDate | null,
                        value?: string | null,
                      ): void {
                        throw new Error('Function not implemented.');
                      }}
                      value={undefined}
                    />
                  }
                  name="dob"
                  control={control}
                  rules={{
                    validate: {
                      required: value =>
                        value !== null || 'This field is required',
                      pattern: value =>
                        !isNaN(new Date(value).getTime()) ||
                        'Invalid date format',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextfieldSelect
                  control={control}
                  name="gender"
                  defaultValue=""
                  required
                  label="Gender"
                  selectProps={{
                    renderValue: value =>
                      value ? (
                        <>{LIST_GENDER[value as Gender]}</>
                      ) : (
                        <Box color="text.secondary">Choose</Box>
                      ),
                  }}
                  error={!!errors?.gender}
                >
                  {Object.keys(LIST_GENDER).map(item => (
                    <MenuItem key={item} value={item}>
                      {LIST_GENDER[item]}
                    </MenuItem>
                  ))}
                </TextfieldSelect>
              </Grid>
            </>
            {/* // )} */}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <TypographyBold variant="subtitle1">
                Do you want to create account on TreatmentGPS?
              </TypographyBold>
              <RadioGroup
                row
                name="createAccount"
                value={createAccount}
                onChange={handleChangeCreateAccount}
                className="ml-8"
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </Grid>

            {createAccount === 'yes' && (
              <>
                <Grid item xs={6}>
                  <TextField
                    tabIndex={5}
                    inputProps={{ maxLength: 255 }}
                    size="small"
                    id="password"
                    label="Password"
                    type={showPassword.password ? 'text' : 'password'}
                    name="password"
                    margin="dense"
                    required
                    fullWidth
                    error={!!errors.password}
                    helperText={
                      !!errors.password ? errors.password.message : ''
                    }
                    variant="outlined"
                    autoComplete="new-password"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                password: !showPassword.password,
                              })
                            }
                          >
                            {showPassword.password ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onFocus={handleOpenPopper}
                    onBlur={handleOpenPopper}
                    inputRef={register({
                      validate:
                        createAccount === 'yes'
                          ? {
                              required: value =>
                                value.trim() !== '' || 'This field is required',
                              pattern: value =>
                                PASSWORD_REGEX.test(value) ||
                                'Password must contain at least 8 characters, one uppercase, one lowercase, and one number',
                            }
                          : undefined,
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    tabIndex={6}
                    inputProps={{ maxLength: 255 }}
                    size="small"
                    id="confirmPassword"
                    label="Confirm"
                    type={showPassword.confirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    margin="dense"
                    required
                    fullWidth
                    error={!!errors.confirmPassword}
                    helperText={
                      !!errors.confirmPassword
                        ? errors.confirmPassword.message
                        : ''
                    }
                    variant="outlined"
                    autoComplete="new-password"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                confirmPassword: !showPassword.confirmPassword,
                              })
                            }
                          >
                            {showPassword.confirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      validate:
                        createAccount === 'yes'
                          ? {
                              required: value =>
                                value.trim() !== '' || 'This field is required',
                              matchesPassword: value =>
                                value === methods.getValues('password') ||
                                'Passwords must match',
                            }
                          : undefined,
                    })}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <ButtonLoading
              className="mt-24 mr-8"
              text="Back"
              color="default"
              callbackClick={onClose}
            />
            <ButtonLoading
              className="mt-24"
              text={'Submit'}
              loading={false}
              color="primary"
              callbackClick={() => {
                if (!emailValue && Object.is(typeContact, 'enail')) {
                  validationEmail(null);
                }
                if (!errorEmail) {
                  handleSave();
                }
              }}
            />
          </Box>
        </>
      )}
    </FormContext>
  );
};

export default React.memo(ContactInformation);
