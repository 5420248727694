import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LinkRouterStyled } from 'share/component_css';
const bgPath1_1 = `${process.env.PUBLIC_URL}/path_1_1.svg`;
const bgPath4 = `${process.env.PUBLIC_URL}/path_4.svg`;
const bgPath6 = `${process.env.PUBLIC_URL}/path_6.svg`;

export const TypoLink = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '1rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
}));

export const BoxWrapHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '80%',
  [theme.breakpoints.up('xl')]: {
    width: '1600px',
  },
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const TypoLinkHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: '#797979',
  fontSize: '1.5rem',
  lineHeight: '30px',
  padding: '13px 42px',
  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.landingPage2,
    borderRadius: '12px',
  },
  cursor: 'pointer',
}));

export const TypoLinkBottom = styled(TypoLinkHeader)(({ theme }) => ({
  color: theme.palette.landingPage2,
  fontSize: '1.25rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
}));

export const TypoLinkDrawment = styled(TypoLinkHeader)(({ theme }) => ({
  width: '94%',
  textAlign: 'center',
  paddingBottom: theme.spacing(1.5),
  paddingTop: theme.spacing(1.5),
}));

export const TypoFooter = styled(Typography)(({ theme }) => ({
  fontƯeight: 400,
  fontSize: '20px',
  lineHeight: '24px',
}));

export const WrapImageSection1 = styled(Box)(({ theme }) => ({
  display: 'grid',
  justifyContent: 'center',
  gridTemplateColumns: '63% 18%',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '556px 158px',
    justifyContent: 'end',
  },
  [theme.breakpoints.up('xl')]: {
    gridTemplateColumns: '735px 207px',
    width: '1600px',
  },
  width: '80%',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const Section1 = styled(Box)(({ theme }) => ({
  paddingBottom: '116px',
  position: 'relative',
  '&::before': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    clipPath: 'polygon(0px 0px, 100% 0px, 100% 83%, 0% 100%)',
    backgroundPosition: '0% -17%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.landingPage2,
    zIndex: -1,
    [theme.breakpoints.up('sm')]: {
      clipPath: 'polygon(0 0, 100% 0, 100% 68%, 0% 100%)',
      backgroundPosition: `0% 52%`,
    },
    [theme.breakpoints.up('md')]: {
      clipPath: ' polygon(0px 0px, 100% 0px, 100% 70%, 0% 100%)',
      backgroundImage: `url(${bgPath1_1})`,
    },
    [theme.breakpoints.up('lg')]: {
      clipPath: ' polygon(0px 0px, 100% 0px, 100% 58%, 0% 100%)',
    },
  },
}));

export const Tag1Section1 = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.landingPage3,
  clipPath: 'polygon(0px 36%, 90% 0px, 100% 64%, 0px 100%)',
  width: '218px',
  height: '120px',
  position: 'absolute',
  bottom: 0,
  left: 0,
  opacity: 0.54,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    clipPath: 'polygon(0px 34%, 90% 0px, 100% 65%, 0px 100%)',
    width: '163px',
    height: '109px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '200px',
  },
}));
export const Tag2Section1 = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.landingPage3,
  position: 'absolute',
  bottom: '-36px',
  left: 0,
  opacity: 0.54,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    clipPath: 'polygon(0px 48%, 94% 0px, 100% 55%, 0px 100%)',
    width: '327px',
    height: '151px',
    display: 'block',
  },
  [theme.breakpoints.up('lg')]: {
    width: '380px',
  },
}));

export const WrapTextSection1 = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '80%',
  [theme.breakpoints.up('xl')]: {
    width: '1600px',
  },
}));

export const TypoTitleSection1 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.common.white,
  fontSize: '2.25rem',
  lineHeight: '1.1',
  paddingTop: theme.spacing(8.5),
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    fontSize: '3.75rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '4.5rem',
  },
}));

export const TypoSection1 = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.common.white,
  width: '100%',
  lineHeight: '1.6',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2.5),
  fontSize: '1rem',
  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '788px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
}));

export const Section2 = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.spacing(7),
  width: '80%',
  [theme.breakpoints.up('xl')]: {
    width: '1600px',
  },
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const Section2Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.875rem',
  lineHeight: 1.1,
  cursor: 'pointer',
  color: theme.palette.landingPage2,
  '&:hover': {
    textDecoration: 'underline',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '4rem',
  },
}));
export const Section2Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: '1.6',
  fontSize: '1rem',
  marginTop: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
}));

export const Secion2WrapLink = styled(LinkRouterStyled)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'auto auto',
  gridGap: theme.spacing(2),
  border: '2px solid',
  borderColor: theme.palette.landingPage2,
  color: theme.palette.landingPage2,
  borderRadius: '12px',
  width: 'fit-content',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.75),
  paddingRight: theme.spacing(1.75),
  '&:hover': {
    backgroundColor: theme.palette.landingPage2,
    color: theme.palette.common.white,
  },
}));

export const LinkRouterStyledSection5 = styled(Secion2WrapLink)(
  ({ theme }) => ({
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.landingPage2,
    },
  }),
);

export const LinkRouterStyledSection6 = styled(
  Secion2WrapLink,
)(({ theme }) => ({}));

export const TypoLinkSection2 = styled(TypoLink)(({ theme }) => ({}));
export const TypoLinkSection6 = styled(TypoLink)(({ theme }) => ({}));

export const BoxWrapSection2 = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  display: 'grid',
  [theme.breakpoints.up('xl')]: {
    width: '42.5%',
    position: 'absolute',
  },
}));

export const Section2WrapImage = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('xl')]: {
    width: '85%',
    marginLeft: 'auto',
  },
}));

export const ImgSection2 = styled(LazyLoadImage)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
}));

export const Section3 = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.landingPage1,
  clipPath: 'polygon(0px 16%, 100% 0px, 100% 84%, 0px 100%)',
  paddingTop: '190px',
  paddingBottom: '190px',
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    clipPath: 'polygon(0 26%, 100% 0, 100% 74%, 0% 100%)',
    paddingTop: '260px',
  },
  [theme.breakpoints.up('md')]: {
    clipPath: ' polygon(0px 26%, 100% 0px, 100% 74%, 0% 100%)',
    paddingTop: '300px',
  },
  [theme.breakpoints.up('lg')]: {
    clipPath: ' polygon(0px 30%, 100% 0px, 100% 70%, 0% 100%)',
    paddingTop: '338px',
    paddingBottom: '300px',
  },
}));

export const TypoTitleSection3 = styled(Section2Title)(({ theme }) => ({
  color: theme.palette.common.white,
  cursor: 'auto',
  marginBottom: theme.spacing(3),
  '&:hover': {
    textDecoration: 'none',
  },
}));

export const TypoSection3 = styled(Section2Text)(({ theme }) => ({
  color: theme.palette.common.white,
  marginTop: 0,
}));

export const WrapSection3 = styled(WrapTextSection1)(({ theme }) => ({}));

export const BoxImage31 = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.landingPage3,
  clipPath: 'polygon(0px 36%, 90% 0px, 100% 64%, 0px 100%)',
  width: '300px',
  height: '120px',
  position: 'absolute',
  top: 0,
  right: 0,
  opacity: 0.54,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    clipPath: 'polygon(0px 44%, 100% 0px, 100% 54%, 23px 100%)',
    width: '353px',
    height: '142px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '437px',
  },
  zIndex: 1,
}));

export const BoxImage32 = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.landingPage3,
  clipPath: 'polygon(0px 36%, 90% 0px, 100% 64%, 0px 100%)',
  width: '218px',
  height: '120px',
  position: 'absolute',
  top: 46,
  right: 0,
  opacity: 0.54,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    clipPath: 'polygon(0px 35%, 100% 0px, 101% 64%, 23px 100%)',
    display: 'block',
    width: '214px',
    height: '109px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '280px',
  },
  zIndex: 1,
}));

export const Section4 = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(16.25),
  paddingBottom: theme.spacing(16.25),
  [theme.breakpoints.up('md')]: {
    background: `url(${bgPath4}) 0% 0% no-repeat`,
  },
}));

export const BoxSection4 = styled(WrapTextSection1)(({ theme }) => ({
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

export const WrapTextSection4 = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(17.5),
  },
}));

export const TypoTitleSection4 = styled(Section2Title)(({ theme }) => ({
  color: theme.palette.common.black,
  marginBottom: theme.spacing(3),
  cursor: 'auto',
  '&:hover': {
    textDecoration: 'none',
  },
}));

export const TypoSection4 = styled(Section2Text)({
  marginTop: 0,
});

export const ImageSection4 = styled(LazyLoadImage)(({ theme }) => ({
  width: '84%',
  flex: '0 0 84%',
  heigth: 'auto',
  marginTop: theme.spacing(3),
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.up('sm')]: {
    width: '48%',
    flex: '0 0 48%',
  },
  [theme.breakpoints.up('md')]: {
    width: '400px',
    flex: '0 0 400px',
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: 0,
  },
}));

export const Section5 = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: "''",
    clipPath: 'polygon(0 24%, 100% 0, 100% 74%, 3% 100%)',
    height: '96px',
    width: '228px',
    position: 'absolute',
    top: '-36px',
    right: 0,
    background: theme.palette.landingPage3,
    opacity: 0.54,
    zIndex: 1,
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  '&::before': {
    content: "''",
    clipPath: 'polygon(0 10%, 100% 0, 100% 90%, 8% 99%)',
    height: '64px',
    width: '75px',
    position: 'absolute',
    top: '-8px',
    right: 0,
    background: theme.palette.landingPage3,
    opacity: 0.54,
    zIndex: 1,
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  backgroundColor: theme.palette.landingPage1,
  clipPath: 'polygon(0 20%, 100% 0%, 100% 80%, 0% 100%)',
  paddingTop: theme.spacing(25),
  paddingBottom: theme.spacing(25),
  [theme.breakpoints.up('lg')]: {
    backgroundColor: 'transparent',
    clipPath: 'none',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const BoxWrapSection5 = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  zIndex: 1,
  display: 'grid',
  justifyContent: 'end',
  width: '80%',
  gridTemplateColumns: '100%',
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    marginTop: '20%',
    position: 'absolute',
    width: '53.5%',
    gridTemplateColumns: '83%',
  },
  [theme.breakpoints.up('xl')]: {
    marginTop: '322px',
  },
}));

export const ImgSection5 = styled(LazyLoadImage)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}));

export const TypoTitleSection5 = styled(Section2Title)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: theme.palette.common.white,
}));

export const TypoSection5 = styled(Section2Text)(({ theme }) => ({
  marginTop: 0,
  color: theme.palette.common.white,
}));

export const TypoLinkSection5 = styled(TypoLink)(({ theme }) => ({}));

export const Section6 = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingBottom: '109px',
    background: `url(${bgPath6})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0% 55%',
  },
  [theme.breakpoints.up('lg')]: {
    paddingBottom: '203px',
  },
  [theme.breakpoints.up('xl')]: {
    paddingBottom: '203px',
    backgroundPosition: 'left bottom',
  },
  paddingBottom: theme.spacing(0),
}));

export const WrapTextSection6 = styled(WrapTextSection4)(({ theme }) => ({}));

export const BoxSection6 = styled(BoxSection4)(({ theme }) => ({}));

export const TypoTitleSection6 = styled(Section2Title)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  cursor: 'auto',
  '&:hover': {
    textDecoration: 'none',
  },
}));

export const TypoSection6 = styled(Section2Text)(({ theme }) => ({
  marginTop: 0,
  width: '100%',
  [theme.breakpoints.up('xl')]: {
    width: '72%',
  },
}));

export const ImageSection6 = styled(ImageSection4)(({ theme }) => ({}));

export const ItemCarousel = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.landingPage2,
  color: theme.palette.common.white,
}));

export const TypoTitleCarousel = styled(Section2Title)(({ theme }) => ({
  color: theme.palette.common.white,
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(4),
  '&:hover': {
    textDecoration: 'none',
  },
  cursor: 'auto',
}));

export const TypoTextCarousel1 = styled(Section2Text)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}));

export const TypoTextCarousel2 = styled(Section2Text)(({ theme }) => ({
  paddingBottom: theme.spacing(7),
}));

export const WrapContentCarousel = styled(Box)(({ theme }) => ({
  maxWidth: '1000px',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const WrapLogo = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  alignItems: 'center',
  gridGap: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  cursor: 'pointer',
}));

export const BoxWrapBottom = styled(BoxWrapHeader)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  gridGap: 32,
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const WrapLinkBottom = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridColumnGap: 42,
  gridRowGap: 24,
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'auto auto auto auto',
  },
}));

export const CarouselArrow = styled(Box)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  alignItems: 'center',
  justifyContent: 'center',
  borderColor: theme.palette.common.white,
  borderRadius: '50%',
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  cursor: 'pointer',
  border: '2px solid',
  color: theme.palette.common.white,
  display: 'flex',
}));
export const CarouselArrowRight = styled(CarouselArrow)(({ theme }) => ({
  right: '95.53px',
}));
export const CarouselArrowLeft = styled(CarouselArrow)(({ theme }) => ({
  left: '95.53px',
}));
