import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from '@material-ui/core';
import { BackdropComponent, BoxStyled, ButtonLoading } from 'components';
import { TypographyBold, VerifyIcon } from 'share/component_css';
import ReactCodeInput from 'react-verification-code-input';
import DialogButton from 'components/DialogButton';
import RefreshIcon from '@material-ui/icons/Refresh';

type Props = {
  open: boolean;
  loading: boolean;
  onClose: (status: boolean) => void;
  handleGenerateOtp: () => void;
  handleVerifyOtpForNonUsers: (values: string) => void;
};

export const DialogOtpConfirmation: React.FC<Props> = ({
  open,
  loading,
  onClose,
  handleGenerateOtp,
  handleVerifyOtpForNonUsers,
}) => {
  const [values, setValues] = useState('');
  const [time, setTime] = useState(60);

  // Resend OTP handler
  const resendOTP = () => {
    handleGenerateOtp();
  };

  // Handle OTP input changes
  const handleChangeCode = (value: string) => {
    setValues(value);
  };

  // Countdown timer for OTP resend
  useEffect(() => {
    if (time === 60) {
      const resendTime = setInterval(() => {
        setTime(prevTime => {
          if (prevTime === 0) {
            clearInterval(resendTime);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
  }, [time]);

  // Automatically verify OTP when 6 digits are entered
  useEffect(() => {
    if (values?.length === 6) {
      handleVerifyOtpForNonUsers(values);
    }
  }, [values]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>User Verification</DialogTitle>
      <DialogContent>
        <BoxStyled alignItems="center" display="flex" flexDirection="column">
          <VerifyIcon />
          <TypographyBold align="center" variant="h6">
            Enter Your Verification Code
          </TypographyBold>
          <BackdropComponent loading={false} />
          <Typography
            className="mb-16 mt-16"
            align="center"
            variant="subtitle2"
          >
            {`We sent you a verification code. Please enter the correct numbers
            here.`}
          </Typography>
          <Box
            display="flex"
            className="mb-16"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <ReactCodeInput
              onChange={handleChangeCode}
              type="text"
              fields={6}
              className="Tes"
            />
            <Box display={'flex'} justifyContent={'center'}>
              <ButtonLoading
                className="mt-24 mr-8"
                text="Back"
                color="default"
                callbackClick={onClose}
              />
              <ButtonLoading
                className="float-right mt-24"
                disabled={!!(time !== 0)}
                loading={loading}
                Icon={<RefreshIcon />}
                text={
                  time === 0
                    ? 'Resend OTP'
                    : `Resend OTP in 00:${time > 9 ? time : `0${time}`}`
                }
                callbackClick={resendOTP}
              />
            </Box>
          </Box>
        </BoxStyled>
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <DialogButton isCancel onClickButton={onClose} />
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DialogOtpConfirmation);
