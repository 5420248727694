import React, { useContext, useCallback } from 'react';
import {
    RelatedArticlesFragment, SubTreatmentFragment
} from 'types.d';
import { TypoThreeDot } from 'share/component_css';
import { CheckHightLightContext } from 'share/context';
import { Box, Grid, Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { sortSubTreatment } from 'share/utils';
import PopoverComponent from './PopoverComponent';
import { useCustomPermission } from 'hooks';
import TreatmentCard from 'modules/home/components/TreatmentCard';

type Props = {
  subTreatments?: RelatedArticlesFragment[];
};

const TreatmentTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: theme.palette.common.black,
    fontSize: '14px',
    maxHeight: '72px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
}))(Tooltip);

export const GridRelatedArticles: React.FC<Props> = ({ subTreatments }) => {
  const checkHightLightContext = useContext(CheckHightLightContext);

  const { isAdmin, isNavigator } = useCustomPermission();

  const location = useLocation();

  const checkHightLight = useCallback(
    (treatmentId: string) => {
      if (checkHightLightContext?.stageHightLight) {
        return checkHightLightContext?.stageHightLight.includes(treatmentId);
      }
      return false;
    },
    [checkHightLightContext],
  );

  const linkDetail = (slug: string, id?: string) => {
    if (slug) {
      return `/treatment/${slug}/`;
    }
    const path = location.pathname.split('/').filter(Boolean);
    return `/${path[0]}/treatment/${id}/`;
  };

  return (
    <>
      <Grid container spacing={2}>
        {sortSubTreatment(subTreatments)?.map((item, index) => {
          return (
            <Grid item key={item._id} xs={12} sm={6}>
              <Box>
                <TreatmentCard
                  key={index}
                  mainVideo={item.mainVideo}
                  mainImage={item.mainImage}
                  slug={item.slug}
                  name={item.name}
                  shortDescription={item.description}
                  description={item?.description}
                  content={item?.content}
                  subsections={[]}
                  childrenLength={4}
                  actions={
                    <Box display="flex" alignItems="center" my={1} m={0} p={0}>
                      <TreatmentTooltip title={item.description || ''}>
                        <TypoThreeDot
                          className={`has-underline font-weight-bold`}
                          variant={
                            checkHightLight(item._id) ? 'h6' : 'subtitle1'
                          }
                          color={
                            checkHightLight(item._id) ? 'primary' : 'inherit'
                          }
                          style={{ paddingTop: '0px', paddingBottom: '0px' }}
                        ></TypoThreeDot>
                      </TreatmentTooltip>
                      {(isAdmin || isNavigator) && (
                        <PopoverComponent
                          treatmentSelected={
                            {
                              ...item,
                              _id: item?._id,
                              name: item?.name,
                              smsMessage: item?.smsMessage,
                              survey: item?.survey,
                            } as SubTreatmentFragment
                          }
                          qrCode={item?.qrCode as string}
                          urlSharing={item?.urlSharing as string}
                        />
                      )}

                      {/* {item.stageLinkTo && (
                        <ChipStyled
                          color={
                            checkHightLight(item._id) ? 'primary' : 'default'
                          }
                          size="small"
                          label={item.stageLinkTo.name}
                        />
                      )} */}
                    </Box>
                  }
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default React.memo(GridRelatedArticles);
